import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function UpdatePhotoContractModal({ toggleModal, closeModal, data }) {
  const [priceData, setPriceData] = useState(false);
  const [fetchStatus, setFetch] = useState(true);
  const [hasAuth, setHasAuth] = useState(false);

  const pinInputRef = useRef(null);

  const formikRef = useRef();

  const handleSubmitOutsideForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  useEffect(() => {
    if (fetchStatus && hasAuth) {
      fetch(process.env.API_URL + "/settings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setPriceData(actualData);
        })
        .catch(() => {
          return null;
        })
        .finally(() => {
          setTimeout(() => {
            setFetch(false);
          }, 150);
        });
    }
  }, [fetchStatus, hasAuth]);

  const adminAuth = async (e) => {
    e.preventDefault();
    const result = await fetch(process.env.API_URL + "/auth/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: "duzenleme",
        password: document.getElementById("pinCode").value,
      }),
    });

    if (result.ok) {
      setFetch(true);
      setHasAuth(true);
    } else if (result.status === 401) {
      toast.error("PIN kodu yanlış!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sunucuya bağlanırken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 50));
  };

  const initialValues = {
    damatAdi: data.damatAdi,
    gelinAdi: data.gelinAdi,
    irtibatTelefonu: data.irtibatTelefonu,
    fotoAlbumDisCekimTarihi:
      moment.utc(data.fotoAlbumDisCekimTarihi).format("YYYY-MM-DD HH:mm") ||
      null,
    secilenPaket: data.secilenPaket,
    dijitalPaket: data.dijitalPaket ? true : false,
    dijitalPaketFiyat: data.dijitalPaketFiyat ? data.dijitalPaketFiyat : null,
    goldPaket: data.goldPaket ? true : false,
    goldPaketFiyat: data.goldPaketFiyat,
    elitPaket: data.elitPaket ? true : false,
    elitPaketFiyat: data.elitPaketFiyat,
    lovePaket: data.lovePaket ? true : false,
    lovePaketFiyat: data.lovePaketFiyat,
    hikayePaket: data.hikayePaket ? true : false,
    hikayePaketFiyat: data.hikayePaketFiyat,
    digerPaket: data.digerPaket ? true : false,
    digerPaketFiyat: data.digerPaket ? data.digerPaketFiyat : undefined,
    fotoAlbumEkstra1: data.fotoAlbumEkstra1 ? true : false,
    fotoAlbumEkstra2: data.fotoAlbumEkstra2 ? true : false,
    fotoAlbumEkstra3: data.fotoAlbumEkstra3 ? true : false,
    aciklama: data.aciklama,
    odemeTuru: "Nakit",
    yeniOdemeAlinan: undefined,
    odemeIndirim: data.odemeIndirim,
    odemeToplam: data.odemeToplam,
  };

  const ValidationSchema = Yup.object().shape({
    damatAdi: Yup.string()
      .min(3, "Damat adı 3 karakterden az olamaz")
      .max(41, "Damat adı 40 karakterden fazla olamaz")
      .required("Damat adı gerekli"),
    gelinAdi: Yup.string()
      .min(3, "Gelin adı 3 karakterden az olamaz")
      .max(41, "Gelin adı 40 karakterden fazla olamaz")
      .required("Gelin adı gerekli"),
    irtibatTelefonu: Yup.string().required("Telefon numarası gerekli"),
    digerPaketFiyat: Yup.string().when("secilenPaket", {
      is: "Diğer Paket",
      then: () => Yup.string().required("Fiyat gerekli"),
      otherwise: () => Yup.string().notRequired(),
    }),
    fotoAlbumEkstra1: Yup.bool().notRequired(),
    fotoAlbumEkstra1ucret: Yup.string().notRequired(),
    fotoAlbumEkstra2: Yup.bool().notRequired(),
    fotoAlbumEkstra2ucret: Yup.string().notRequired(),
    fotoAlbumEkstra3: Yup.bool().notRequired(),
    fotoAlbumEkstra3ucret: Yup.string().notRequired(),
    aciklama: Yup.string()
      .max(101, "100 karakterden fazla olamaz")
      .notRequired(),
    yeniOdemeAlinan: Yup.number()
      .test("max", "Ön ödeme toplam tutardan fazla olamaz", function () {
        return newPayment <= data.odemeToplam;
      })
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .notRequired(),
    odemeIndirim: Yup.number()
      .notRequired()
      .test(
        "max",
        "Ödeme iskontosu toplam tutardan fazla olamaz",
        function (value) {
          if (!value) return true;
          return value <= data.odemeToplam;
        },
      ),
  });

  const validateIrtibatTelefonu = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Telefon numarası eksik olmamalıdır";
    }

    return undefined;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const cleanPhoneNumber = values.irtibatTelefonu.replace(/\D/g, "");

    const result = fetch(process.env.API_URL + "/photoContracts", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        id: data.id,
        data: {
          damatAdi: values.damatAdi || null,
          gelinAdi: values.gelinAdi || null,
          irtibatTelefonu: cleanPhoneNumber || null,
          fotoAlbumDisCekimTarihi:
            moment.utc(values.fotoAlbumDisCekimTarihi) || null,
          secilenPaket: values.secilenPaket || null,
          secilenPaketFiyat: values.secilenPaket
            ? values.secilenPaket === "Dijital Paket"
              ? priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat
                ? priceData.dijitalPaketFiyat
                : data.dijitalPaketFiyat
              : values.secilenPaket === "Gold Paket"
                ? priceData.goldPaketFiyat !== data.goldPaketFiyat
                  ? priceData.goldPaketFiyat
                  : data.goldPaketFiyat
                : values.secilenPaket === "Elit Paket"
                  ? priceData.elitPaketFiyat !== data.elitPaketFiyat
                    ? priceData.elitPaketFiyat
                    : data.elitPaketFiyat
                  : values.secilenPaket === "Love Paket"
                    ? priceData.lovePaketFiyat !== data.lovePaketFiyat
                      ? priceData.lovePaketFiyat
                      : data.lovePaketFiyat
                    : values.secilenPaket === "Hikaye Paket"
                      ? priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                      : values.digerPaketFiyat
            : null,
          dijitalPaket: values.secilenPaket === "Dijital Paket" || null,
          dijitalPaketFiyat:
            values.secilenPaket === "Dijital Paket"
              ? priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat
                ? priceData.dijitalPaketFiyat
                : data.dijitalPaketFiyat
              : data.dijitalPaketFiyat,
          goldPaket: values.secilenPaket === "Gold Paket" || null,
          goldPaketFiyat:
            values.secilenPaket === "Gold Paket"
              ? priceData.goldPaketFiyat !== data.goldPaketFiyat
                ? data.goldPaketFiyat
                : priceData.goldPaketFiyat
              : data.goldPaketFiyat,
          elitPaket: values.secilenPaket === "Elit Paket" || null,
          elitPaketFiyat:
            values.secilenPaket === "Elit Paket"
              ? priceData.elitPaketFiyat !== data.elitPaketFiyat
                ? data.elitPaketFiyat
                : priceData.elitPaketFiyat
              : data.elitPaketFiyat,
          lovePaket: values.secilenPaket === "Love Paket" || null,
          lovePaketFiyat:
            values.secilenPaket === "Love Paket"
              ? priceData.lovePaketFiyat !== data.lovePaketFiyat
                ? data.lovePaketFiyat
                : priceData.lovePaketFiyat
              : data.lovePaketFiyat,
          hikayePaket: values.secilenPaket === "Hikaye Paket" || null,
          hikayePaketFiyat:
            values.secilenPaket === "Hikaye Paket"
              ? priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                ? data.hikayePaketFiyat
                : priceData.hikayePaketFiyat
              : data.hikayePaketFiyat,
          digerPaket: values.secilenPaket === "Diğer Paket" || null,
          digerPaketFiyat:
            values.secilenPaket === "Diğer Paket"
              ? values.digerPaketFiyat
              : null,
          fotoAlbumEkstra1: values.fotoAlbumEkstra1 || null,
          fotoAlbumEkstra1ucret: values.fotoAlbumEkstra1
            ? priceData.fotoAlbumEkstra1ucret !== data.fotoAlbumEkstra1ucret
              ? data.fotoAlbumEkstra1ucret
              : priceData.fotoAlbumEkstra1ucret
            : data.fotoAlbumEkstra1ucret,
          fotoAlbumEkstra2: values.fotoAlbumEkstra2 || null,
          fotoAlbumEkstra2ucret: values.fotoAlbumEkstra2
            ? priceData.fotoAlbumEkstra2ucret !== data.fotoAlbumEkstra2ucret
              ? data.fotoAlbumEkstra2ucret
              : priceData.fotoAlbumEkstra2ucret
            : data.fotoAlbumEkstra2ucret,
          fotoAlbumEkstra3: values.fotoAlbumEkstra3 || null,
          fotoAlbumEkstra3ucret: values.fotoAlbumEkstra3
            ? priceData.fotoAlbumEkstra3ucret !== data.fotoAlbumEkstra3ucret
              ? data.fotoAlbumEkstra3ucret
              : priceData.fotoAlbumEkstra3ucret
            : data.fotoAlbumEkstra3ucret,
          aciklama: values.aciklama || null,
          odemeTuru: values.odemeTuru,
          odemeAlinan: String(newPayment),
          odemeKalan: String(Number(data.odemeToplam) - Number(newPayment)),
          odemeIndirim:
            Number(values.odemeIndirim) > 0 ? values.odemeIndirim : null,
        },
      }),
    });

    const response = await result;

    if (response.ok) {
      toast.success("Sözleşme başarıyla güncellendi!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme güncellenirken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 1200));

    setSubmitting(false);
    setHasAuth(false);
    closeModal();
  };

  const [newPayment, setNewPayment] = useState(Number(data.odemeAlinan));

  const handlePaymentChange = (e) => {
    const newPaymentValue = Number(e.target.value);
    const calculatedPayment = Number(data.odemeAlinan) + newPaymentValue;

    setNewPayment(calculatedPayment);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size={hasAuth ? "xl" : "md"}
        fullscreen="lg-down"
        scrollable
        centered={!hasAuth}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!hasAuth ? "Giriş Yap" : "Fotoğraf Sözleşmesi Güncelle"}
            {hasAuth && (
              <div className="text-muted fs-6">
                Sözleşme Kodu: 500{data.contractID} - Toplam Fiyat:{" "}
                {data.odemeToplam}₺
              </div>
            )}
          </Modal.Title>
        </Modal.Header>

        {hasAuth ? (
          <>
            <Modal.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                innerRef={formikRef}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">
                        Müşteri Bilgileri
                      </div>
                    </div>
                    <div className="row mb-1 px-5 px-md-0 justify-content-center">
                      <div className="form-floating col-lg-3 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="damatAdi"
                          name="damatAdi"
                          placeholder="Damat Adı"
                        />
                        <label className="form-label ms-3" htmlFor="damatAdi">
                          Damat Adı
                        </label>
                        <ErrorMessage
                          name="damatAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-3 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="gelinAdi"
                          name="gelinAdi"
                          placeholder="Gelin adı"
                        />
                        <label className="form-label ms-3" htmlFor="gelinAdi">
                          Gelin Adı
                        </label>
                        <ErrorMessage
                          name="gelinAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>
                    <div className="row mt-1 px-5 px-md-0 justify-content-center">
                      <div className="form-floating col-lg-3 mb-3">
                        <Field
                          name="irtibatTelefonu"
                          placeholder="İrtibat Telefonu"
                          validate={validateIrtibatTelefonu}
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask="0 (999) 999-9999"
                              maskChar=""
                              className="form-control"
                              id="irtibatTelefonu"
                              name="irtibatTelefonu"
                              placeholder="0 (   ) ___-____"
                            />
                          )}
                        </Field>
                        <label
                          className="form-label ms-3"
                          htmlFor="irtibatTelefonu"
                        >
                          İrtibat Telefonu
                        </label>
                        <ErrorMessage
                          name="irtibatTelefonu"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-md-3 mb-3">
                        <Field
                          type="datetime-local"
                          className="form-control"
                          id="fotoAlbumDisCekimTarihi"
                          name="fotoAlbumDisCekimTarihi"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          style={{ fontSize: ".8em" }}
                          htmlFor="fotoAlbumDisCekimTarihi"
                        >
                          Dış Çekim Tarihi
                        </label>
                        <ErrorMessage
                          name="fotoAlbumDisCekimTarihi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <hr className="w-75 mx-auto" />

                    <div className="row mb-2">
                      <div className="fs-5 fw-bold text-center">
                        Albüm Paketi
                      </div>
                    </div>

                    <div className="row px-5 justify-content-center">
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="radio"
                          className="form-check-input"
                          id="dijitalPaket"
                          name="secilenPaket"
                          value="Dijital Paket"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="dijitalPaket"
                        >
                          Dijital Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.dijitalPaketFiyat !== data.dijitalPaketFiyat ? "text-warning" : ""}`}
                          htmlFor="dijitalPaket"
                        >
                          {priceData.dijitalPaketFiyat !==
                          data.dijitalPaketFiyat
                            ? priceData.dijitalPaketFiyat
                            : data.dijitalPaketFiyat}
                          ₺
                        </label>
                      </div>

                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="radio"
                          className="form-check-input"
                          id="goldPaket"
                          name="secilenPaket"
                          value="Gold Paket"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="goldPaket"
                        >
                          Gold Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.goldPaketFiyat !== data.goldPaketFiyat ? "text-warning" : ""}`}
                          htmlFor="goldPaket"
                        >
                          {priceData.goldPaketFiyat !== data.goldPaketFiyat
                            ? priceData.goldPaketFiyat
                            : data.goldPaketFiyat}
                          ₺
                        </label>
                      </div>

                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="radio"
                          className="form-check-input"
                          id="elitPaket"
                          name="secilenPaket"
                          value="Elit Paket"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="elitPaket"
                        >
                          Elit Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.elitPaketFiyat !== data.elitPaketFiyat ? "text-warning" : ""}`}
                          htmlFor="elitPaket"
                        >
                          {priceData.elitPaketFiyat !== data.elitPaketFiyat
                            ? priceData.elitPaketFiyat
                            : data.elitPaketFiyat}
                          ₺
                        </label>
                      </div>
                    </div>

                    <div className="row px-5 justify-content-center mt-lg-2 mb-4">
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="radio"
                          className="form-check-input"
                          id="lovePaket"
                          name="secilenPaket"
                          value="Love Paket"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="lovePaket"
                        >
                          Love Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.lovePaketFiyat !== data.lovePaketFiyat ? "text-warning" : ""}`}
                          htmlFor="lovePaket"
                        >
                          {priceData.lovePaketFiyat !== data.lovePaketFiyat
                            ? priceData.lovePaketFiyat
                            : data.lovePaketFiyat}
                          ₺
                        </label>
                      </div>
                      <div className="form-check col-lg-auto me-3 my-auto pt-3 pe-lg-0">
                        <Field
                          type="radio"
                          className="form-check-input"
                          id="hikayePaket"
                          name="secilenPaket"
                          value="Hikaye Paket"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="hikayePaket"
                        >
                          Hikaye Paket
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.hikayePaketFiyat !== data.hikayePaketFiyat ? "text-warning" : ""}`}
                          htmlFor="hikayePaket"
                        >
                          {priceData.hikayePaketFiyat !== data.hikayePaketFiyat
                            ? priceData.hikayePaketFiyat
                            : data.hikayePaketFiyat}
                          ₺
                        </label>
                      </div>

                      <div
                        className="col-lg-2 me-3 my-auto pt-3 pe-lg-0"
                        title="Diğer Paket"
                      >
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="radio"
                              className="form-check-input"
                              id="digerPaket"
                              name="secilenPaket"
                              value="Diğer Paket"
                            />
                          </div>
                          <Field
                            type="text"
                            name="digerPaketFiyat"
                            placeholder="Diğer"
                          >
                            {({ field }) => (
                              <InputMask
                                {...field}
                                type="text"
                                className="form-control"
                                id="digerPaketFiyat"
                                mask="9999999999"
                                maskChar={""}
                                placeholder="Diğer"
                                disabled={values.secilenPaket !== "Diğer Paket"}
                                onChange={handleChange}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="digerPaketFiyat"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <hr className="w-75 mx-auto" />

                    <div className="row mb-4">
                      <div className="fs-5 fw-bold text-center">
                        Albüm Bilgileri
                      </div>
                    </div>

                    <div className="row px-5 justify-content-center mb-1">
                      <div className="form-check col-md-auto mb-3">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="fotoAlbumEkstra1"
                          name="fotoAlbumEkstra1"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="fotoAlbumEkstra1"
                        >
                          Klip
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.fotoAlbumEkstra1ucret !== data.fotoAlbumEkstra1ucret ? "text-warning" : "text-normal"}`}
                          htmlFor="fotoAlbumEkstra1"
                        >
                          {priceData.fotoAlbumEkstra1ucret !==
                          data.fotoAlbumEkstra1ucret
                            ? priceData.fotoAlbumEkstra1ucret
                            : data.fotoAlbumEkstra1ucret}
                          ₺
                        </label>
                      </div>

                      <div className="form-check col-md-auto mb-3">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="fotoAlbumEkstra2"
                          name="fotoAlbumEkstra2"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="fotoAlbumEkstra2"
                        >
                          Aile Albümü
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.fotoAlbumEkstra2ucret !== data.fotoAlbumEkstra2ucret ? "text-warning" : "text-normal"}`}
                          htmlFor="fotoAlbumEkstra2"
                        >
                          {priceData.fotoAlbumEkstra2ucret !==
                          data.fotoAlbumEkstra2ucret
                            ? priceData.fotoAlbumEkstra2ucret
                            : data.fotoAlbumEkstra2ucret}
                          ₺
                        </label>
                      </div>

                      <div className="form-check col-md-auto ps-md-4 mb-3">
                        <Field
                          type="checkbox"
                          className="form-check-input"
                          id="fotoAlbumEkstra3"
                          name="fotoAlbumEkstra3"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          htmlFor="fotoAlbumEkstra3"
                        >
                          Kanvas
                        </label>
                        <label
                          className={`form-check-label ms-2 ${priceData.fotoAlbumEkstra3ucret !== data.fotoAlbumEkstra3ucret ? "text-warning" : "text-normal"}`}
                          htmlFor="fotoAlbumEkstra3"
                        >
                          {priceData.fotoAlbumEkstra3ucret !==
                          data.fotoAlbumEkstra3ucret
                            ? priceData.fotoAlbumEkstra3ucret
                            : data.fotoAlbumEkstra3ucret}
                          ₺
                        </label>
                      </div>
                    </div>

                    <hr className="w-75 mx-auto" />

                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">Açıklama</div>
                    </div>

                    <div className="row mb-1 px-5 px-md-0 justify-content-center">
                      <div className="form-floating col-lg-7 mb-3">
                        <Field name="aciklama" placeholder="Sıra No">
                          {({ field }) => (
                            <InputMask
                              type="text"
                              className="form-control"
                              id="aciklama"
                              mask=""
                              maskChar={""}
                              placeholder=""
                              onChange={handleChange}
                              {...field}
                            />
                          )}
                        </Field>
                        <label
                          className="form-check-label ms-2"
                          htmlFor="aciklama"
                        >
                          Açıklama & Ek Bilgiler
                        </label>
                        <ErrorMessage
                          name="aciklama"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <hr className="w-75 mx-auto" />

                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">
                        Ön Ödeme ve İskonto
                      </div>

                      <div className="text-center mt-2 text-muted">
                        Toplam Alınan Ödeme: {newPayment}₺
                      </div>
                    </div>

                    <div className="row mb-1 px-5 px-md-0 justify-content-center">
                      <div className="form-floating col-lg-2 mb-3">
                        <select
                          className="form-select"
                          id="odemeTuru"
                          name="odemeTuru"
                          defaultValue="Nakit"
                          onChange={handleChange}
                        >
                          <option value="Nakit">Nakit</option>
                          <option value="Havale">Havale</option>
                        </select>
                        <label
                          className="form-label ms-2 fw-bold"
                          htmlFor="gelinlikFiltresi"
                          style={{ fontSize: ".95em" }}
                        >
                          Ödeme Türü
                        </label>
                      </div>
                      <div className="form-floating col-lg-2 mb-3">
                        <Field
                          type="text"
                          name="yeniOdemeAlinan"
                          placeholder="İskonto"
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              type="text"
                              className="form-control"
                              id="yeniOdemeAlinan"
                              mask="9999999999"
                              maskChar={""}
                              placeholder=""
                              onChange={(e) => {
                                handlePaymentChange(e);
                                handleChange(e);
                              }}
                            />
                          )}
                        </Field>
                        <label
                          className="form-check-label ms-2 "
                          htmlFor="yeniOdemeAlinan"
                        >
                          Yeni Ön Ödeme
                        </label>

                        <ErrorMessage
                          name="yeniOdemeAlinan"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-2 mb-3">
                        <Field
                          type="text"
                          name="odemeIndirim"
                          placeholder="İskonto"
                        >
                          {({ field }) => (
                            <InputMask
                              type="text"
                              className="form-control"
                              id="odemeIndirim"
                              mask="9999999999"
                              maskChar={""}
                              placeholder=""
                              onChange={handleChange}
                              {...field}
                            />
                          )}
                        </Field>
                        <label
                          className="form-check-label ms-2 "
                          htmlFor="odemeIndirim"
                        >
                          İskonto
                        </label>
                        <ErrorMessage
                          name="odemeIndirim"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <hr className="w-75 mx-auto" />
                    <div className="row mt-3 px-4 px-md-0 justify-content-center">
                      <div
                        className="alert alert-warning col-lg-9 px-4 py-3"
                        role="alert"
                      >
                        <i className="bi bi-info-circle-fill me-2" />
                        Sözleşmeyi oluştururken girilen fiyat bilgileriyle
                        (paket ücretleri ve albüm ekstraları gibi) güncel fiyat
                        bilgileri aynı değilse, fiyatlar sarı renkle gösterilir
                        ve sözleşmeyi güncellediğinizde bu fiyatlar değişmez.
                        Fiyatı değiştirmek için başka bir seçenek seçmelisiniz.
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={toggleModal}>
                İptal Et
              </Button>
              <Button variant="primary" onClick={handleSubmitOutsideForm}>
                Güncelle
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body className="py-5 d-flex justify-content-center flex-column">
              <form onSubmit={adminAuth}>
                <div className="row justify-content-center text-md-start text-center mb-3 text-muted">
                  Devam edebilmek için 6 haneli PIN kodunuzu giriniz.
                </div>
                <div className="row justify-content-center mb-4">
                  <div className="col-md-auto">
                    <InputMask
                      mask="999999"
                      maskChar=""
                      type="password"
                      className="form-control"
                      id="pinCode"
                      name="pinCode"
                      placeholder="PIN Kodu"
                      ref={pinInputRef}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 text-muted gap-4">
                  <div
                    className="col-md-4 btn btn-secondary"
                    onClick={toggleModal}
                  >
                    İptal
                  </div>
                  <button type="submit" className="col-md-4 btn btn-primary">
                    Giriş Yap
                  </button>
                </div>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={450}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        transition:Flip
      />
    </>
  );
}

export default UpdatePhotoContractModal;
