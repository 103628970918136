import React from "react";

export default function Pagination({ totalPages, currentPage, setFetch }) {
  const pageSelectors = [];
  const total = totalPages.current;
  const current = currentPage.current;
  const delta = 2;

  const range = [];
  for (
    let i = Math.max(2, current - delta);
    i <= Math.min(total - 1, current + delta);
    i++
  ) {
    range.push(i);
  }

  if (current - delta > 2) {
    range.unshift("...");
  }
  if (current + delta < total - 1) {
    range.push("...");
  }
  range.unshift(1);
  if (total > 1) {
    range.push(total);
  }

  range.forEach((page, index) => {
    if (page === "...") {
      pageSelectors.push(
        <li key={index + 1} className="page-item">
          <span className="page-link">...</span>
        </li>,
      );
    } else {
      pageSelectors.push(
        <li
          key={page}
          id={`page${page}`}
          className={`page-item pe-auto ${current === page ? "active" : ""}`}
          aria-current="page"
        >
          <a
            className="page-link pe-auto"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (current !== page) {
                currentPage.current = page;
                setFetch(true);
              }
            }}
          >
            {page}
          </a>
        </li>,
      );
    }
  });

  return pageSelectors;
}
