import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import "./NewPrintCanvas.css";
import { usePrintContext } from "../contextWrapper/PrintContext";

const NewBridalPrintCanvas = () => {
  const { printData, switchBackToReality, shouldPrint, setShouldPrint } =
    usePrintContext();

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (shouldPrint === "bridal") {
      handlePrint();

      switchBackToReality();

      setShouldPrint(false);
    }
  }, [
    printData,
    shouldPrint,
    handlePrint,
    switchBackToReality,
    setShouldPrint,
  ]);

  return (
    <>
      <div className="container-fluid bg-white border mt-3 d-none">
        <div ref={componentRef} className="" id="printCanvas">
          <div className="row">
            <div className="col-3 mt-5 ms-4 ps-4 pe-0">
              {printData.aciklama && (
                <>
                  <div className="fs-5 fw-bold">AÇIKLAMA:</div>
                  <div
                    className="mt-2"
                    style={{
                      maxHeight: "100px",
                      fontSize: ".75em",
                      lineHeight: "1.2em",
                    }}
                  >
                    {printData.aciklama}
                  </div>
                </>
              )}
            </div>
            <div className="col mt-5 ps-0 pe-0 me-0">
              <div className="col text-center fs-1 fw-bold">
                MİSS LUNA & ALTINPARK
              </div>
              <div
                className="col text-center fs-2 fw-bold"
                style={{ lineHeight: "2em" }}
              >
                CAMERAY PHOTOGRAPHY
              </div>
              <div
                className="col text-center mb-2"
                style={{ fontSize: "1.2em" }}
              >
                KAMPANYA PAKET SÖZLEŞMESİ
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col ms-4">
              <b className="pe-1">SÖZLEŞME TARİHİ:</b>
              {moment(printData.sozlesmeTarihi).format("DD.MM.YYYY")}
            </div>
            <div className="col text-end me-4">
              <b className="pe-1">SIRA NO:</b>
              000{printData.contractID}
            </div>
          </div>

          <hr className="mx-4 my-2" />

          <div className="row">
            <div className="col-3 ms-4 ps-4 me-2 pe-0">
              {/*MÜŞTERİ BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ps-2" style={{ lineHeight: "1.55em" }}>
                <div className="row fw-bold fs-5">MÜŞTERİ</div>
                <div className="row fw-bold fs-5">BİLGİLERİ</div>
              </div>
              <div
                className="row mt- pt-4"
                style={{
                  fontSize: ".85em",
                  letterSpacing: "0px",
                  lineHeight: "1.45em",
                }}
              >
                <div className="row ps-3 pe-0">
                  <div className="row">
                    <div className="col ps-3">DAMAT</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div
                    className="row ps-4 pt-1 pe-0"
                    style={{ height: "50px" }}
                  >
                    {printData.damatAdi}
                  </div>
                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe0" style={{ height: "40px" }}>
                    {printData.damatTelefonu}
                  </div>
                  <div className="row">
                    <div className="col ps-3">GELİN</div>
                  </div>
                  <div className="row">
                    <div className="col fw-bold">İSİM SOYİSİM:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "45px" }}>
                    {printData.gelinAdi}
                  </div>

                  <div className="row">
                    <div className="col">TELEFON:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {printData.gelinTelefonu}
                  </div>

                  <div className="row">
                    <div className="col">DÜĞÜN TARİHİ:</div>
                  </div>
                  <div className="row ps-4 pe-0" style={{ height: "35px" }}>
                    {moment(printData.dugunTarihi).format("DD.MM.YYYY")}
                  </div>
                  <div className="row">
                    <div className="col fw-bold" style={{ fontSize: "1.25em" }}>
                      ADRES:
                    </div>
                  </div>
                  <div
                    className="row ps-4 pe-0"
                    style={{ height: "95px", lineHeight: "1.35em" }}
                  >
                    {printData.adres}
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold" style={{ fontSize: "1.35em" }}>
                    İRTİBAT:
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2"
                    style={{
                      height: "100px",
                      fontSize: "1.35em",
                      letterSpacing: ".8px",
                    }}
                  >
                    05540161638
                  </div>
                </div>
                <hr className="" style={{ width: "75%" }} />
                <div className="row">
                  <div className="col fw-bold fs-5 text-center py-2">
                    ALTINPARK
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col fw-bold pt-2 pb-2"
                    style={{ fontSize: "1.25em" }}
                  >
                    ADRES:
                  </div>
                </div>
                <div className="row ps-4 fw-bold mb-2">
                  GÖKÇE ÖREN MAH. ESKİ ORHANELİ YOLU 6.KM OSMANGAZİ/BURSA
                </div>
                <hr className="" style={{ width: "75%" }} />
              </div>
              {/*MÜŞTERİ BİLGİLERİ BİTİŞ*/}
            </div>
            <div className="col ms-1" style={{ fontSize: ".95em" }}>
              <div className="row ps-2">
                {/*GELİNLİK BİLGİLERİ BAŞLANGIÇ*/}
                <div className="col fw-bold" style={{ fontSize: "1.1em" }}>
                  • GELİNLİK BİLGİLERİ
                </div>{" "}
              </div>
              <div className="row ms-1" style={{ fontSize: ".85em" }}>
                <div className="col-auto fw-bold ps-2 pe-1 mt-auto">
                  GELİNLİK:
                </div>
                {printData.gelinlikModeli === "Ağ Kesim" ? (
                  <div className="col-auto fw-bold my-auto ps-0 pe-1">
                    AĞ KESİM
                    <i className="bi bi-x-circle ms-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold my-auto ps-0 pe-1">
                    AĞ KESİM
                    <i className="bi bi-circle ms-2 fs-5" />
                  </div>
                )}

                {printData.gelinlikModeli === "Helen" ? (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    HELEN
                    <i className="bi bi-x-circle ps-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    HELEN
                    <i className="bi bi-circle ps-2 fs-5" />
                  </div>
                )}
                {printData.gelinlikModeli === "Prenses" ? (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    PRENSES
                    <i className="bi bi-x-circle ms-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold ps-0 pe-1">
                    PRENSES
                    <i className="bi bi-circle ms-2 fs-5" />
                  </div>
                )}
                {printData.gelinlikModeli === "Özel Dikim" ? (
                  <div className="col-auto fw-bold ps-0 pe-0">
                    ÖZEL DİKİM
                    <i className="bi bi-x-circle ms-2 fs-5" />
                  </div>
                ) : (
                  <div className="col-auto fw-bold ps-0 pe-0">
                    ÖZEL DİKİM
                    <i className="bi bi-circle ms-2 fs-5" />
                  </div>
                )}
              </div>
              {/*GELİNLİK BİLGİLERİ BİTİŞ*/}
              {/*KAFTAN BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ms-1 ps-2" style={{ fontSize: ".87em" }}>
                {printData.kaftanID ? (
                  <div className="col-4 fw-bold ps-2 my-auto me-3">
                    <i className="bi bi-x-circle fs-5 me-1" />
                    KAFTAN NO: {printData.kaftanID}
                  </div>
                ) : (
                  <div className="col-4 fw-bold ps-2 my-auto me-3">
                    <i className="bi bi-circle fs-5 me-1" />
                    KAFTAN NO:
                  </div>
                )}

                {printData.kinalikID ? (
                  <div className="col fw-bold my-auto">
                    <i className="bi bi-x-circle fs-5 me-1 ms-1" />
                    KINALIK NO: {printData.kinalikID}
                  </div>
                ) : (
                  <div className="col fw-bold my-auto">
                    <i className="bi bi-circle fs-5 me-1 ms-1" />
                    KINALIK NO:
                  </div>
                )}
              </div>
              {/*KAFTAN BİLGİLERİ BİTİŞ*/}
              {/*GELİNLİK TESLİMATI BİLGİLERİ BAŞLANGIÇ*/}
              <div className="row ms-1 ps-2" style={{ fontSize: ".87em" }}>
                <div className="col-6">
                  <div className="row">
                    <div className="col-auto fw-bold ps-2 pe-0">
                      GELİNLİK TESLİMATI:
                    </div>
                    <div className="col-auto ps-1">
                      {printData.gelinlikTeslimati &&
                        moment(printData.gelinlikTeslimati).format(
                          "DD.MM.YYYY"
                        )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto fw-bold ps-2 pe-0">
                      DAMATLIK TESLİMATI:
                    </div>
                    <div className="col-auto ps-1">
                      {printData.damatlikTeslimati &&
                        moment(printData.damatlikTeslimati).format(
                          "DD.MM.YYYY"
                        )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-auto fw-bold ps-2 pe-0">
                      KAFTAN - KINALIK TESLİMATI:
                    </div>
                    <div className="col-auto ps-1">
                      {printData.kinalikKiralikBaslangic &&
                        moment(printData.kinalikKiralikBaslangic).format(
                          "DD.MM.YYYY"
                        ) +
                          " - " +
                          moment(printData.kinalikKiralikBitis).format(
                            "DD.MM.YYYY"
                          )}
                    </div>
                  </div>
                  <div className="row ps-2 fw-bold">TERZİLİK DETAYLARI:</div>
                </div>
                <div
                  className="col-6 ps-2 pt-2"
                  style={{ lineHeight: "1.10em", fontSize: ".95em" }}
                >
                  <div className="row">
                    {Number(printData.gelinlikOzelDikim) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">ÖZEL DİKİM:</div>
                        <div className="col-auto ps-0">
                          {printData.gelinlikOzelDikim} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.gelinlikEkstrasi) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">GELİNLİK:</div>
                        <div className="col-auto ps-0">
                          {printData.gelinlikEkstrasi} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.duvakUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">DUVAK:</div>
                        <div className="col-auto ps-0">
                          {printData.duvakUcreti} ₺
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    {Number(printData.tacUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">TAÇ:</div>
                        <div className="col-auto ps-0">
                          {printData.tacUcreti} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.terzilikUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">TERZİLİK:</div>
                        <div className="col-auto ps-0">
                          {printData.terzilikUcreti} ₺
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    {Number(printData.kaftanUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-0">KINALIK:</div>
                        <div className="col-auto ps-0 pe-0">
                          {printData.kaftanUcreti} ₺
                        </div>
                      </>
                    )}
                    {Number(printData.kuruTemizlemeUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">KURU TEM.:</div>
                        <div className="col-auto ps-0">
                          {printData.kuruTemizlemeUcreti} ₺
                        </div>
                      </>
                    )}
                  </div>
                  <div className={`row`}>
                    {Number(printData.damatlikUcreti) > 0 && (
                      <>
                        <div className="col-auto fw-bold pe-1">DAMATLIK:</div>
                        <div className="col-auto ps-0">
                          {printData.damatlikUcreti} ₺
                        </div>
                      </>
                    )}
                    <div className="col-auto fw-bold pe-1">TOPLAM:</div>
                    <div className="col-auto ps-0">
                      {Number(printData.gelinlikEkstrasi) +
                        Number(printData.duvakUcreti) +
                        Number(printData.tacUcreti) +
                        Number(printData.terzilikUcreti) +
                        Number(printData.kaftanUcreti) +
                        Number(printData.kuruTemizlemeUcreti) +
                        Number(printData.damatlikUcreti) || "-"}{" "}
                      ₺
                    </div>
                  </div>
                </div>
              </div>
              {/*GELİNLİK TESLİMATI BİLGİLERİ BİTİŞ*/}
              {/*TERZİLİK BİLGİLERİ BAŞLANGIÇ*/}
              {printData.gelinlikDetaylari || printData.terzilikDetaylari ? (
                <div className="row ms-1 ps-2" style={{ fontSize: ".87em" }}>
                  <div className="col">
                    {printData.gelinlikDetaylari ? (
                      <div
                        className="row px-2"
                        style={{
                          fontSize: ".85em",
                          height: "20px",
                          lineHeight: "1.2em",
                        }}
                      >
                        {printData.gelinlikDetaylari ? (
                          <>
                            {printData.gelinlikDetaylari} <br />
                          </>
                        ) : null}
                        {printData.terzilikDetaylari
                          ? printData.terzilikDetaylari
                          : ""}
                      </div>
                    ) : (
                      <div
                        className="row px-2"
                        style={{
                          fontSize: ".85em",
                          height: "20px",
                          lineHeight: "1.2em",
                        }}
                      >
                        {printData.terzilikDetaylari
                          ? printData.terzilikDetaylari
                          : ""}
                      </div>
                    )}
                  </div>
                </div>
              ) : undefined}
              {/*TERZİLİK BİLGİLERİ BİTİŞ*/}
              <hr className="me-3 ms-1 mt-2 mb-2" style={{ width: "95%" }} />
              {/*ALBÜM BİLGİLERİ BAŞLANGIÇ*/}
              <div className="thin-left-border">
                <div className="row ps-2">
                  <div className="col fw-bold">
                    <div className="row" style={{ fontSize: "1.1em" }}>
                      • ALBÜM BİLGİLERİ
                    </div>
                    <div className="row ps-1" style={{ fontSize: ".87em" }}>
                      PAKET İÇERİĞİ:
                    </div>
                  </div>
                  <div className="col-auto text-end fw-bold pe-5">
                    <div
                      className="col text-center"
                      style={{ fontSize: ".85em" }}
                    >
                      <div className="row">
                        <div className="col text-center">
                          CAMERAY PHOTOGRAPHY
                        </div>
                      </div>
                      0536 601 52 22
                    </div>
                  </div>
                  <div
                    className="row mt-1 ps-3"
                    style={{ fontSize: ".87em", lineHeight: "1.2em" }}
                  >
                    <div
                      className="col-5 ps-0 fw-bold"
                      style={{ color: "gray" }}
                    >
                      30X50 TEK ALBÜM
                    </div>
                    <div className="col pe-0 ps-4">
                      <div className="row">
                        <div className="col-7 text-end fw-bold pe-0">
                          Dış çekim tarih:
                        </div>
                        <div className="col-auto ps-1 pe-0">
                          {printData.fotoAlbumDisCekimTarihi
                            ? moment(printData.fotoAlbumDisCekimTarihi).format(
                                "DD.MM.YYYY"
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row ps-3"
                    style={{ fontSize: ".87em", lineHeight: "1.2em" }}
                  >
                    <div
                      className="col-5 ps-0 fw-bold"
                      style={{ color: "gray" }}
                    >
                      ALTINPARK DIŞ ÇEKİM
                    </div>
                    <div className="col pe-0 ps-4">
                      <div className="row">
                        <div className="col-7 text-end fw-bold pe-0">saat:</div>
                        <div className="col-auto ps-1">
                          {printData.fotoAlbumDisCekimTarihi
                            ? moment
                                .utc(printData.fotoAlbumDisCekimTarihi)
                                .format("HH:mm")
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row ps-3"
                    style={{ fontSize: ".87em", lineHeight: "1.2em" }}
                  >
                    <div
                      className="col-6 ps-0 fw-bold"
                      style={{ color: "gray" }}
                    >
                      DİJİTAL TESLİM
                    </div>
                  </div>
                </div>
                {/*ALBÜM BİLGİLERİ BİTİŞ*/}
                {/*EKSTRA BİLGİLERİ BAŞLANGIÇ*/}
                <div className="row mt-1" style={{ fontSize: ".87em" }}>
                  <div className="col-auto fw-bold pe-1">
                    EKSTRA İSTENENLER:
                  </div>
                  {printData.fotoAlbumEkstra1 ||
                  printData.fotoAlbumEkstra2 ||
                  printData.fotoAlbumEkstra3 ? (
                    <div
                      className="col ps-3 mt-1"
                      style={{
                        height: "45px",
                        lineHeight: "1.13em",
                      }}
                    >
                      <div className="row">
                        {printData.fotoAlbumEkstra1
                          ? printData.fotoAlbumEkstra1 +
                            " - " +
                            printData.fotoAlbumEkstra1ucret +
                            "₺"
                          : ""}
                      </div>
                      <div className="row">
                        {printData.fotoAlbumEkstra2
                          ? printData.fotoAlbumEkstra2 +
                            " - " +
                            printData.fotoAlbumEkstra2ucret +
                            "₺"
                          : ""}
                      </div>
                      <div className="row">
                        {printData.fotoAlbumEkstra3
                          ? printData.fotoAlbumEkstra3 +
                            " - " +
                            printData.fotoAlbumEkstra3ucret +
                            "₺"
                          : ""}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row" style={{ fontSize: ".87em" }}>
                  <div className="col-auto  fw-bold pe-1">ALBÜM EKSTRASI:</div>
                  {printData.albumDetaylari ? (
                    <div
                      className="col-6 ps-2 my-auto"
                      style={{
                        fontSize: ".85em",
                        maxHeight: "20px",
                        lineHeight: "1.2em",
                      }}
                    >
                      {printData.albumDetaylari}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col text-end ps-2 fw-bold pe-5 me-2">
                    {printData.albumUcreti ? printData.albumUcreti + "₺" : ""}
                  </div>
                </div>
                {/*EKSTRA BİLGİLERİ BİTİŞ*/}

                <hr className="me-3 ms-1 mt-1 mb-1" style={{ width: "90%" }} />

                {/*KUAFÖR BİLGİLERİ BAŞLANGIÇ*/}
                <div className="row ps-2">
                  <div className="col fw-bold">• KUAFÖR BİLGİLERİ</div>
                  <div
                    className="col-auto text-end text-center pe-5 fw-bold"
                    style={{ fontSize: ".85em" }}
                  >
                    EMRAH UYAR
                  </div>
                </div>

                <div className="row fw-bold" style={{ fontSize: ".85em" }}>
                  <div className="col">PAKET İÇERİĞİ:</div>
                  <div className="col-auto text-end pe-5 me-5">
                    Bay & Bayan Kuaförü
                  </div>
                </div>

                <div
                  className="row ps-3 fw-bold"
                  style={{
                    fontSize: ".85em",
                    lineHeight: "1.2em",
                    color: "gray",
                  }}
                >
                  <div className="col ps-0">
                    GELİN BAŞI VEYA TÜRBAN TASARIMI
                  </div>
                  <div className="col-auto text-end pe-5 me-4 text-black">
                    Telefon: 0224 452 8899
                  </div>
                </div>
                <div
                  className="row ps-3 fw-bold"
                  style={{
                    fontSize: ".85em",
                    lineHeight: "1.15em",
                    color: "gray",
                  }}
                >
                  <div className="col ps-0">
                    SU BAZLI PORSELEN MAKYAJ (KRYOLAN)
                  </div>
                  <div className="col-auto text-end pe-5 me-4 text-black fw-normal">
                    WhatsApp: 0543 352 1565
                  </div>
                </div>
                <div
                  className="row ps-3 fw-bold"
                  style={{
                    fontSize: ".85em",
                    lineHeight: "1.15em",
                    color: "gray",
                  }}
                >
                  <div className="col ps-0">
                    PROFESYONEL KİRPİK DEKOLTE MAKYAJI
                  </div>
                </div>
                {/*KUAFÖR BİLGİLERİ BİTİŞ*/}

                <hr className="me-3 ms-1 mt-1 mb-1" style={{ width: "90%" }} />

                {/*ARAÇ SÜSLEME BİLGİLERİ BAŞLANGIÇ*/}
                <div className="row ps-2">
                  <div className="col fw-bold">
                    <div className="row fw-bold" style={{ fontSize: "1.2em" }}>
                      • ARAÇ SÜSLEME BİLGİLERİ
                    </div>
                    <div className="row ps-1" style={{ fontSize: ".87em" }}>
                      PAKET İÇERİĞİ:
                    </div>
                  </div>
                  <div className="col-auto text-end fw-bold pe-5">
                    <div
                      className="col text-center"
                      style={{ fontSize: ".85em" }}
                    >
                      <div className="row">
                        <div className="col text-center">MELİSA ÇİÇEKÇİLİK</div>
                      </div>
                      0536 709 59 86
                    </div>
                  </div>

                  <div
                    className="row ps-3"
                    style={{ fontSize: ".9em", color: "gray" }}
                  >
                    <div className="col ps-0 fw-bold">
                      CANLI ÇİÇEK ARAÇ SÜSLEME
                    </div>
                  </div>
                </div>
                {/*ARAÇ SÜSLEME BİLGİLERİ BİTİŞ*/}

                <hr className="me-3 ms-1 mt-1 mb-1" style={{ width: "90%" }} />

                <div
                  className="row mb-1 mt-2 px-2 ps-3 fw-bold"
                  style={{
                    fontSize: ".72em",
                    lineHeight: "1.4em",
                    letterSpacing: ".2px",
                  }}
                >
                  <div className="row ">
                    • ALTINPARK DIŞ ÇEKİM GİRİŞ ÜCRETİ PAKET İÇERİĞİNE DAHİLDİR.
                  </div>
                  <div className="row ">
                    • ÖZEL DİKİM GELİNLİK SEÇİMİ VE TESLİMATI BU SÖZLEŞME İLE
                    GERÇEKLEŞECEKTİR.
                  </div>
                  <div className="row ">
                    • GELİNLİK ÇEKİM RANDEVUSUNDAN 15 GÜN ÖNCE ALINMALIDIR.
                  </div>
                  <div className="row ">
                    • KAFTAN YADA NİŞANLIK DÜĞÜNDEN 1 GÜN ÖNCE ALINMALIDIR VE
                    DÜĞÜNDEN 1 GÜN SONRA TESLİM EDİLMELİDİR.
                  </div>
                  <div className="row ">
                    • TESLİM EDİLMEK ÜZERE ALINAN ÜRÜNLERİN SORUMLULUĞU (KURU
                    TEMİZLEME DAHİL) MÜŞTERİYE AİTTİR.
                  </div>
                  <div className="row ">
                    • KURU TEMİZLEMESİ YAPILMADAN GETİRİLEN ÜRÜNLER İÇİN 1000TL
                    KURU TEMİZLEME BEDELİ MÜŞTERİDEN TAHSİL EDİLECEKTİR.
                  </div>
                  <div className="row ">
                    • PAKET TOPLAM ÜCRETİ EKSTRALAR VE KDV HARİÇ{" "}
                    {printData.paketFiyati}₺ &apos;DİR.
                  </div>
                  <div className="row ">
                    • RANDEVUSU OLUŞTURULMAYAN HİÇ BİR HİZMETTEN FİRMAMIZ
                    SORUMLU DEĞİLDİR.
                  </div>
                  <div className="row ">
                    • YAPILAN ANLAŞMALAR KESİNLİKLE İPTAL EDİLMEMEKTEDİR. KOPORA
                    İADESİ DE YAPILMAMAKTADIR.
                  </div>
                  <div className="row ">
                    • ÖZEL DİKİM GELİNLİKLERDE TEK TARAFLI FESİH YAPILDIĞINDA
                    PAKET ÜCRETİNİN TAMAMI FİRMAYA ÖDENMEK ZORUNDADIR.
                  </div>
                  <div className="row ">
                    • KİRALAMA YAPILAN ÜRÜNLERDE GECİKME BEDELİ GÜNLÜK 1000
                    TL'DİR.
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3 fs-5 fw-bold">SATICI:</div>
                  <div className="col-4">
                    <div className="row fs-5 fw-bold">ALICI:</div>
                    <div className="row">
                      <div className="col p-0 pe-1" style={{ height: "45px" }}>
                        {printData.damatAdi}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col p-0 fw-bold">TOPLAM:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeToplam} ₺
                      </div>
                    </div>
                    <div className="row">
                      <div className="col p-0 fw-bold">KAPORA:</div>
                      <div className="col p-0 mt-auto ps-1">
                        {printData.odemeAlinan} ₺
                      </div>
                    </div>
                    {Number(printData.odemeIndirim) > 0 && (
                      <div className="row">
                        <div className="col p-0 fw-bold">İSKONTO:</div>
                        <div className="col p-0 mt-auto ps-1">
                          {printData.odemeIndirim} ₺
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col p-0 fw-bold">KALAN:</div>
                      <div className="col p-0 mt-auto">
                        {printData.odemeKalan} ₺
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBridalPrintCanvas;
