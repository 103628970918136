import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, isNaN } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePrintContext } from "../../printCanvases/contextWrapper/PrintContext";

function NewPhotoContract({ setFormDirty }) {
  const navigate = useNavigate();
  const formRef = useRef();
  const digerPaketRef = useRef();

  const validate = () => {
    if (formRef.current.dirty) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  };

  const [paketler, setPaketler] = useState([
    {
      name: "Dijital Paket",
      value: 0,
      checked: true,
    },
    {
      name: "Gold Paket",
      value: 0,
      checked: false,
    },
    {
      name: "Elit Paket",
      value: 0,
      checked: false,
    },
    {
      name: "Love Paket",
      value: 0,
      checked: false,
    },
    {
      name: "Hikaye Paket",
      value: 0,
      checked: false,
    },
    {
      name: "Diğer Paket",
      value: 0,
      checked: false,
    },
  ]);

  const [paketTutari, setPaketTutari] = useState(0);
  const [albumTutari, setAlbumTutari] = useState(0);

  const calculatePaket = (e) => {
    if (e.target.value === "Diğer Paket") {
      setPaketTutari(0);
      setToplamTutar(() => Number(toplamTutar) - Number(paketTutari));
      setPaketler(
        paketler.map((paket) => {
          if (paket.name === e.target.value) {
            return {
              ...paket,
              checked: true,
            };
          } else {
            return {
              ...paket,
              checked: false,
            };
          }
        }),
      );
    } else {
      digerPaketRef.current.value = "0";
      paketler.map((paket) => {
        if (paket.name === e.target.value) {
          setToplamTutar(
            () =>
              Number(toplamTutar) + Number(paket.value) - Number(paketTutari),
          );
          setPaketTutari(paket.value);
          setPaketler(
            paketler.map((paket) => {
              if (paket.name === e.target.value) {
                return {
                  ...paket,
                  checked: true,
                };
              } else {
                return {
                  ...paket,
                  checked: false,
                };
              }
            }),
          );
        }
      });
    }
  };

  const [, setKlip] = useState(false);
  const [, setAileAlbumu] = useState(false);
  const [, setKanvas] = useState(false);

  const { setPrintData, switchToPrintPage, setShouldPrint } = usePrintContext();

  const handlePrintButtonClick = (data) => {
    setPrintData(data);
    setShouldPrint("photo");
    switchToPrintPage();
  };

  const [fetchStatus, setFetch] = useState(true);

  const [stageOne, setStageOne] = useState(true);
  const [stageTwo, setStageTwo] = useState(false);

  const [prices, setPrices] = useState({});
  const [toplamTutar, setToplamTutar] = useState(0);

  const validationSchemaStageOne = Yup.object().shape({
    damatAdi: Yup.string()
      .min(3, "Damat adı 3 karakterden az olamaz")
      .max(41, "Damat adı 40 karakterden fazla olamaz")
      .required("Damat adı gerekli"),
    gelinAdi: Yup.string()
      .min(3, "Gelin adı 3 karakterden az olamaz")
      .max(41, "Gelin adı 40 karakterden fazla olamaz")
      .required("Gelin adı gerekli"),
    irtibatTelefonu: Yup.string().required("Telefon numarası gerekli"),
    fotoAlbumDisCekimTarihi: Yup.date()
      .typeError("Dış çekim tarihi geçersiz")
      .notRequired(),
  });

  const validateContactNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length !== 11) {
      return "Eksik veya hatalı telefon numarası girdiniz";
    }

    return undefined;
  };

  const ValidationSchemaStageTwo = Yup.object().shape({
    secilenPaket: Yup.mixed(),
    digerPaket: Yup.bool().notRequired(),
    digerPaketFiyat: Yup.string().when("secilenPaket", {
      is: "Diğer Paket",
      then: () => Yup.string().required("Diğer paket fiyatı gerekli"),
      otherwise: () => Yup.string().notRequired(),
    }),
    fotoAlbumEkstra1: Yup.bool().notRequired(),
    fotoAlbumEkstra1ucret: Yup.string().notRequired(),
    fotoAlbumEkstra2: Yup.bool().notRequired(),
    fotoAlbumEkstra2ucret: Yup.string().notRequired(),
    fotoAlbumEkstra3: Yup.bool().notRequired(),
    fotoAlbumEkstra3ucret: Yup.string().notRequired(),
    idSecenek: Yup.boolean().notRequired(),
    id: Yup.string().notRequired(),
    aciklama: Yup.string()
      .max(101, "100 karakterden fazla olamaz")
      .notRequired(),
    odemeTuru: Yup.string()
      .oneOf(["Nakit", "Havale"])
      .required("Ödeme türü gerekli"),
    odemeAlinan: Yup.number()
      .test("max", "Ön ödeme toplam tutardan fazla olamaz", function (value) {
        return value <= toplamTutar;
      })
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .required("Ödeme tutarı gerekli"),
    odemeIndirim: Yup.number()
      .notRequired()
      .test(
        "max",
        "Ödeme iskontosu toplam tutardan fazla olamaz",
        function (value) {
          if (!value) return true;
          return value <= toplamTutar;
        },
      ),
  });

  const [stageOneValues, setStageOneValues] = useState({
    damatAdi: "",
    gelinAdi: "",
    irtibatTelefonu: "",
    fotoAlbumDisCekimTarihi: "",
  });

  const [stageTwoValues, setStageTwoValues] = useState({
    secilenPaket: "Dijital Paket",
    digerPaket: false,
    digerPaketFiyat: "",
    id: "",
    aciklama: "",
    fotoAlbumEkstra1: "",
    fotoAlbumEkstra2: "",
    fotoAlbumEkstra3: "",
    odemeTuru: "Nakit",
    odemeAlinan: "",
    odemeIndirim: "",
  });

  useEffect(() => {
    if (fetchStatus) {
      fetch(process.env.API_URL + "/settings", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setPrices(actualData);
          setPaketler([
            {
              name: "Dijital Paket",
              value: actualData.dijitalPaketFiyat,
              checked: true,
            },
            {
              name: "Gold Paket",
              value: actualData.goldPaketFiyat,
              checked: false,
            },
            {
              name: "Elit Paket",
              value: actualData.elitPaketFiyat,
              checked: false,
            },
            {
              name: "Love Paket",
              value: actualData.lovePaketFiyat,
              checked: false,
            },
            {
              name: "Hikaye Paket",
              value: actualData.hikayePaketFiyat,
              checked: false,
            },
            {
              name: "Diğer Paket",
              value: 0,
              checked: false,
            },
          ]);
          setStageTwoValues({
            ...stageTwoValues,
          });
          setToplamTutar(Number(actualData.dijitalPaketFiyat));
          setPaketTutari(actualData.dijitalPaketFiyat);
        })
        .catch(() => {
          toast.error(
            "Fiyat bilgileri okunurken bir hata meydana geldi, lütfen tekrar deneyin",
            {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            },
          );
        })
        .finally(() => {
          setFetch(false);
          setTimeout(() => {}, 100);
        });
    }
  }, [stageTwoValues, fetchStatus]);

  const toggleStageOne = (values) => {
    if (values) {
      setStageTwoValues(values);
    }
    setStageOne(true);
    setStageTwo(false);
  };

  const toggleStageTwo = (values) => {
    if (values) {
      const cleanNumber = values.irtibatTelefonu.replace(/\D/g, "");
      const cleanedValues = {
        ...values,
        irtibatTelefonu: cleanNumber,
      };
      values = cleanedValues;
      setStageOneValues(values);
    }
    setStageTwo(true);
    setStageOne(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let toplamUcret = Number(toplamTutar);

    if (Number(values.odemeIndirim) > 0) {
      toplamUcret -= Number(values.odemeIndirim);
    }

    let alinanUcret = Number(toplamUcret) - Number(values.odemeAlinan);

    if (isNaN(Number(toplamUcret)) || isNaN(Number(alinanUcret))) {
      return toast.error(
        "Fiyat bilgileri normal gözükmüyor! Lütfen gözden geçirin veya baştan başlayın",
        {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        },
      );
    }

    setStageTwoValues(values);
    const result = await fetch(process.env.API_URL + "/photoContracts", {
      method: "post",
      headers: {
        "content-type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        odemeTuru: values.odemeTuru,
        data: {
          contractID: Number(values.id.substring(3) > 0)
            ? Number(values.id.substring(3))
            : undefined,
          aciklama: values.aciklama || null,
          sozlesmeTarihi: new Date(),
          damatAdi: stageOneValues.damatAdi,
          gelinAdi: stageOneValues.gelinAdi,
          irtibatTelefonu: stageOneValues.irtibatTelefonu,
          secilenPaket: paketler
            .filter((paket) => paket.checked)
            .map((paket) => paket.name)
            .join(", "),
          secilenPaketFiyat: Number(paketTutari),
          dijitalPaket: paketler[0].checked ? "Dijital Paket" : null,
          dijitalPaketFiyat: prices.dijitalPaketFiyat,
          goldPaket: paketler[1].checked ? "Gold Paket" : null,
          goldPaketFiyat: prices.goldPaketFiyat,
          elitPaket: paketler[2].checked ? "Elit Paket" : null,
          elitPaketFiyat: prices.elitPaketFiyat,
          lovePaket: paketler[3].checked ? "Love Paket" : null,
          lovePaketFiyat: prices.lovePaketFiyat,
          hikayePaket: paketler[4].checked ? "Hikaye Paket" : null,
          hikayePaketFiyat: prices.hikayePaketFiyat,
          digerPaket: paketler[5].checked ? "Diğer Paket" : null,
          digerPaketFiyat: paketler[5].checked ? paketTutari : null,
          fotoAlbumEkstra1: values.fotoAlbumEkstra1 ? "Klip" : null,
          fotoAlbumEkstra1ucret: prices.fotoAlbumEkstra1ucret,
          fotoAlbumEkstra2: values.fotoAlbumEkstra2 ? "Aile Albümü" : null,
          fotoAlbumEkstra2ucret: prices.fotoAlbumEkstra2ucret,
          fotoAlbumEkstra3: values.fotoAlbumEkstra3 ? "Kanvas" : null,
          fotoAlbumEkstra3ucret: prices.fotoAlbumEkstra3ucret,
          fotoAlbumDisCekimTarihi: stageOneValues.fotoAlbumDisCekimTarihi
            ? moment.utc(stageOneValues.fotoAlbumDisCekimTarihi)
            : null,
          odemeAlinan: Number(values.odemeAlinan),
          odemeToplam: toplamUcret,
          odemeIndirim:
            Number(values.odemeIndirim) > 0
              ? Number(values.odemeIndirim)
              : null,
          odemeKalan: alinanUcret,
        },
      }),
    });

    if (result.ok) {
      toast.success("Sözleşme başarıyla oluşturuldu!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });

      const contract = await result.json();

      setFormDirty(false);

      handlePrintButtonClick(contract);
      await new Promise((resolve) => setTimeout(resolve, 200));

      navigate("/photo-contracts/success");
    } else if (result.status === 400) {
      toast.error("Bu sıra no ile zaten bir sözleşme var!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme oluşturulurken bir hata meydana geldi!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    setSubmitting(false);
  };

  return (
    <Fragment>
      <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2">
        <div className="row">
          <div className="col fs-4 fw-bold">Yeni Foto Albüm Sözleşmesi</div>
          <div className="col text-end">
            <Button
              className="shadow-sm fw-bold"
              type="button"
              variant="outline-success"
              as={Link}
              to="/photo-contracts"
            >
              <i className="bi bi-house-door-fill me-1" />
              Anasayfa
            </Button>
          </div>
        </div>
      </div>

      {stageOne && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2">
            <div className="row">
              <div className="col fs-2 fw-bold ms-lg-3">Müşteri Bilgileri</div>
            </div>
            <Formik
              initialValues={stageOneValues}
              validationSchema={validationSchemaStageOne}
              onSubmit={toggleStageTwo}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="stageOneContainer container shadow-sm p-3 mt-3 border">
                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="damatAdi"
                          name="damatAdi"
                          placeholder="Damat Adı"
                        />
                        <label className="form-label ms-3" htmlFor="damatAdi">
                          Damat Adı
                        </label>
                        <ErrorMessage
                          name="damatAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="gelinAdi"
                          name="gelinAdi"
                          placeholder="Gelin adı"
                        />
                        <label className="form-label ms-3" htmlFor="gelinAdi">
                          Gelin Adı
                        </label>
                        <ErrorMessage
                          name="gelinAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center">
                      <div className="form-floating col-lg-4 mb-3">
                        <Field
                          name="irtibatTelefonu"
                          placeholder="İrtibat Telefonu"
                          validate={validateContactNumber}
                        >
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask="0 (999) 999-9999"
                              maskChar=""
                              className="form-control"
                              id="irtibatTelefonu"
                              name="irtibatTelefonu"
                              placeholder="0 (___) ___-____"
                            />
                          )}
                        </Field>
                        <label
                          className="form-label ms-3"
                          htmlFor="irtibatTelefonu"
                        >
                          İrtibat Telefonu
                        </label>
                        <ErrorMessage
                          name="irtibatTelefonu"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-md-4 mb-3">
                        <Field
                          type="datetime-local"
                          className="form-control"
                          id="fotoAlbumDisCekimTarihi"
                          name="fotoAlbumDisCekimTarihi"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          style={{ fontSize: ".8em" }}
                          htmlFor="fotoAlbumDisCekimTarihi"
                        >
                          Dış Çekim Tarihi
                        </label>
                        <ErrorMessage
                          name="fotoAlbumDisCekimTarihi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg text-lg-end">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="stageOneDevamBtn btn btn-primary fw-bold"
                        >
                          <i className="bi bi-arrow-right-circle-fill me-1" />
                          Devam et
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      {stageTwo && (
        <Fragment>
          <div className="container-xl bg-light shadow-sm p-3 my-3 border rounded-2 mb-5 ">
            <div className="row">
              <div className="col fs-2 ms-lg-3 fw-bold text-center text-md-start">
                Albüm Bilgileri
              </div>
              {paketTutari > 0 && (
                <div className="col-lg-3 ">
                  <div className="shadow-lg my-2 text-center card text-bg-success fw-bold">
                    <div className="card-title fs-6 my-2">
                      Albüm Paketi: {paketTutari || ""}₺
                    </div>
                  </div>
                </div>
              )}
              {albumTutari > 0 && (
                <div className="col-lg-3">
                  <div className="shadow-lg my-2 text-center card text-bg-success fw-bold">
                    <div className="card-title fs-6 my-2">
                      Albüm Ekstrası: {albumTutari || ""}₺
                    </div>
                  </div>
                </div>
              )}
              <div className="col-lg-3">
                <div className="shadow-lg my-2 text-center card text-bg-primary fw-bold">
                  <div
                    className="toplamTutar card-title fs-6 my-2"
                    title={`${paketTutari > 0 ? `Albüm paketi\t\t${paketTutari}₺\n` : ""}${albumTutari > 0 ? `Albüm ekstraları\t${albumTutari}₺\n` : ""}`}
                  >
                    Toplam Tutar: {toplamTutar || ""}₺
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={stageTwoValues}
              validationSchema={ValidationSchemaStageTwo}
              onSubmit={handleSubmit}
              innerRef={formRef}
              validate={validate}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="container shadow-sm p-3 mt-3 border bg-white rounded-4">
                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Albüm Paketi
                        </div>
                      </div>

                      <div
                        className="row justify-content-center ms-4 ms-lg-0 mt-3 mb-4"
                        title={prices.dijitalPaketFiyat + "₺"}
                      >
                        <div className="form-check col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="dijitalPaket"
                            name="secilenPaket"
                            value="Dijital Paket"
                            onClick={(e) => {
                              calculatePaket(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="dijitalPaket"
                          >
                            Dijital Paket
                          </label>
                        </div>

                        <div
                          className="form-check col-lg-auto me-3 my-auto pt-3"
                          title={prices.goldPaketFiyat + "₺"}
                        >
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="goldPaket"
                            name="secilenPaket"
                            value="Gold Paket"
                            onClick={(e) => {
                              calculatePaket(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="goldPaket"
                          >
                            Gold Paket
                          </label>
                        </div>

                        <div
                          className="form-check col-lg-auto me-3 my-auto pt-3"
                          title={prices.elitPaketFiyat + "₺"}
                        >
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="elitPaket"
                            name="secilenPaket"
                            value="Elit Paket"
                            onClick={(e) => {
                              calculatePaket(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="elitPaket"
                          >
                            Elit Paket
                          </label>
                        </div>
                      </div>
                      <div
                        className="row justify-content-center ms-4 ms-lg-0 mt-3 mb-4"
                        title={prices.lovePaketFiyat + "₺"}
                      >
                        <div className="form-check col-lg-auto me-3 my-auto pt-3">
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="lovePaket"
                            name="secilenPaket"
                            value="Love Paket"
                            onClick={(e) => {
                              calculatePaket(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="lovePaket"
                          >
                            Love Paket
                          </label>
                        </div>

                        <div
                          className="form-check col-lg-auto me-3 my-auto pt-3"
                          title={prices.hikayePaketFiyat + "₺"}
                        >
                          <Field
                            type="radio"
                            className="form-check-input"
                            id="hikayePaket"
                            name="secilenPaket"
                            value="Hikaye Paket"
                            onClick={(e) => {
                              calculatePaket(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="hikayePaket"
                          >
                            Hikaye Paket
                          </label>
                        </div>
                        <div
                          className="col-lg-2 col-md-4 me-3 my-auto pt-3"
                          title="Diğer Paket"
                        >
                          <div className="input-group">
                            <div className="input-group-text">
                              <Field
                                type="radio"
                                className="form-check-input"
                                id="digerPaket"
                                name="secilenPaket"
                                value="Diğer Paket"
                                onClick={(e) => {
                                  calculatePaket(e);
                                }}
                              />
                            </div>
                            <Field
                              type="text"
                              name="digerPaketFiyat"
                              placeholder="Diğer"
                            >
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  id="digerPaketFiyat"
                                  mask="999999999999999"
                                  maskChar={""}
                                  placeholder="Diğer"
                                  disabled={
                                    values.secilenPaket !== "Diğer Paket"
                                  }
                                  ref={digerPaketRef}
                                  onChange={(e) => {
                                    setPaketTutari(e.target.value);
                                    setToplamTutar(
                                      (prev) =>
                                        prev -
                                        Number(paketTutari) +
                                        Number(e.target.value),
                                    );
                                    handleChange(e);
                                  }}
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="digerPaketFiyat"
                              component="div"
                              className="text-danger mt-1 ms-1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Albüm Ekstraları
                        </div>
                      </div>

                      <div className="row m-3 justify-content-center">
                        <div className="form-check col-md-auto mb-3">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="fotoAlbumEkstra1"
                            name="fotoAlbumEkstra1"
                            onChange={(e) => {
                              setKlip((prev) => !prev);
                              if (e.target.checked) {
                                setToplamTutar(
                                  (prev) =>
                                    prev + Number(prices.fotoAlbumEkstra1ucret),
                                );
                                setAlbumTutari(
                                  (prev) =>
                                    prev + Number(prices.fotoAlbumEkstra1ucret),
                                );
                              } else {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.fotoAlbumEkstra1ucret),
                                );

                                setAlbumTutari(
                                  (prev) =>
                                    prev - Number(prices.fotoAlbumEkstra1ucret),
                                );
                              }
                              handleChange(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="fotoAlbumEkstra1"
                          >
                            Klip - {prices.fotoAlbumEkstra1ucret}₺
                          </label>
                        </div>

                        <div className="form-check col-md-auto mb-3">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="fotoAlbumEkstra2"
                            name="fotoAlbumEkstra2"
                            onChange={(e) => {
                              setAileAlbumu((prev) => !prev);
                              if (e.target.checked) {
                                setToplamTutar(
                                  (prev) =>
                                    prev + Number(prices.fotoAlbumEkstra2ucret),
                                );
                                setAlbumTutari(
                                  (prev) =>
                                    prev + Number(prices.fotoAlbumEkstra2ucret),
                                );
                              } else {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.fotoAlbumEkstra2ucret),
                                );
                                setAlbumTutari(
                                  (prev) =>
                                    prev - Number(prices.fotoAlbumEkstra2ucret),
                                );
                              }
                              handleChange(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="fotoAlbumEkstra2"
                          >
                            Aile Albümü - {prices.fotoAlbumEkstra2ucret}₺
                          </label>
                        </div>

                        <div className="form-check col-md-auto ps-md-4 mb-3">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="fotoAlbumEkstra3"
                            name="fotoAlbumEkstra3"
                            onChange={(e) => {
                              setKanvas((prev) => !prev);
                              if (e.target.checked) {
                                setToplamTutar(
                                  (prev) =>
                                    prev + Number(prices.fotoAlbumEkstra3ucret),
                                );

                                setAlbumTutari(
                                  (prev) =>
                                    prev + Number(prices.fotoAlbumEkstra3ucret),
                                );
                              } else {
                                setToplamTutar(
                                  (prev) =>
                                    prev - Number(prices.fotoAlbumEkstra3ucret),
                                );
                                setAlbumTutari(
                                  (prev) =>
                                    prev - Number(prices.fotoAlbumEkstra3ucret),
                                );
                              }
                              handleChange(e);
                            }}
                          />
                          <label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="Kanvas"
                          >
                            Kanvas - {prices.fotoAlbumEkstra3ucret}₺
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col-md offset-md-2 fw-bold fs-4">
                          Sözleşme Bilgileri
                        </div>
                      </div>
                      <div className="row m-3 mb-1">
                        <div className="form-check col-md-auto offset-md-2 mb-1 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="idSecenek"
                            name="idSecenek"
                          />
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="idSecenek"
                          >
                            Sözleşme Sıra No
                          </label>
                        </div>
                        <div className="form-check col-md-auto offset-md-1 mb-1 my-auto">
                          <Field
                            type="checkbox"
                            className="form-check-input"
                            id="aciklamaSecenek"
                            name="aciklamaSecenek"
                          />
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="aciklamaSecenek"
                          >
                            Açıklama & Ek Bilgiler
                          </label>
                        </div>
                      </div>
                      <div className="row m-3 mb-1">
                        <div className="form-floating col-lg-auto offset-lg-2 ps-lg-0 mb-3">
                          <Field name="id" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="id"
                                mask="500999999999999999"
                                maskChar={""}
                                placeholder="500_"
                                disabled={!values.idSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2 "
                            htmlFor="id"
                          >
                            Sıra No
                          </label>
                          <ErrorMessage
                            name="id"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-5 offset-lg-0 mb-3">
                          <Field name="aciklama" placeholder="Sıra No">
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="aciklama"
                                mask=""
                                maskChar={""}
                                placeholder=""
                                disabled={!values.aciklamaSecenek}
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>

                          <label
                            className="form-check-label ms-2"
                            htmlFor="aciklama"
                          >
                            Açıklama & Ek Bilgiler
                          </label>
                          <ErrorMessage
                            name="aciklama"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container-md rounded-4 shadow-sm p-2 border bg-light mt-4">
                      <div className="row mt-3">
                        <div className="col offset-md-2 fw-bold fs-4">
                          Ön Ödeme ve İndirim
                        </div>
                      </div>

                      <div className="row m-3 mb-1 justify-content-center">
                        <div className="form-floating col-lg-2 mb-3">
                          <select
                            className="form-select"
                            id="odemeTuru"
                            name="odemeTuru"
                            defaultValue="Nakit"
                            onChange={handleChange}
                          >
                            <option value="Nakit">Nakit</option>
                            <option value="Havale">Havale</option>
                          </select>
                          <label
                            className="form-label ms-2 fw-bold"
                            htmlFor="gelinlikFiltresi"
                            style={{ fontSize: ".95em" }}
                          >
                            Ödeme Türü
                          </label>
                        </div>
                        <div className="form-floating col-lg-2 mb-3">
                          <Field
                            type="text"
                            name="odemeAlinan"
                            placeholder="İskonto"
                          >
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="odemeAlinan"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-check-label ms-2 "
                            htmlFor="odemeAlinan"
                          >
                            Ön Ödeme
                          </label>

                          <ErrorMessage
                            name="odemeAlinan"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                        <div className="form-floating col-lg-2 mb-3">
                          <Field
                            type="text"
                            name="odemeIndirim"
                            placeholder="İskonto"
                          >
                            {({ field }) => (
                              <InputMask
                                type="text"
                                className="form-control"
                                id="odemeIndirim"
                                mask="9999999999"
                                maskChar={""}
                                placeholder=""
                                onChange={handleChange}
                                {...field}
                              />
                            )}
                          </Field>
                          <label
                            className="form-check-label ms-2 "
                            htmlFor="odemeIndirim"
                          >
                            İndirim
                          </label>
                          <ErrorMessage
                            name="odemeIndirim"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5 mb-2">
                      <div className="col-lg text-lg-end">
                        <button
                          type="button"
                          className="btn btn-dark fw-bold me-2"
                          onClick={() => {
                            toggleStageOne(values);
                          }}
                        >
                          <i className="bi bi-arrow-left-circle-fill me-1" />
                          Geri dön
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-success shadow fw-bold"
                        >
                          <i className="bi bi-floppy-fill me-1" />
                          Kaydet ve Yazdır
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fragment>
      )}

      <ToastContainer
        position="top-center"
        autoClose={2500}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}

export default NewPhotoContract;
