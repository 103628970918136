import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import "./NewPrintCanvas.css";
import { usePrintContext } from "../contextWrapper/PrintContext";

const NewPhotoPrintCanvas = () => {
  const { printData, switchBackToReality, shouldPrint, setShouldPrint } =
    usePrintContext();

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (shouldPrint === "photo") {
      handlePrint();

      switchBackToReality();

      setShouldPrint(false);
    }
  }, [
    printData,
    shouldPrint,
    handlePrint,
    switchBackToReality,
    setShouldPrint,
  ]);

  return (
    <>
      <div className="container-fluid bg-white border mt-3 d-none">
        <div
          ref={componentRef}
          className="container-fluid d-flex flex-column p-0 m-0"
          id="printCanvas"
        >
          <div className="row justify-content-center">
            <div className="col-auto mt-5 ps-0 pe-0 me-0">
              <div className="col text-center fs-2 fw-bold mb-2">
                CAMERA AY PHOTOGRAPHY
              </div>
              <div
                className="col-auto text-center mb-2"
                style={{ fontSize: ".9em" }}
              >
                Karaman, İzmir Yolu Cd. Yerkaya İş Merkezi <br />
                82/A K:6 D:7 Nilüfer/Bursa <br />
                Tel: 0 536 601 52 22
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col ms-4">
              <b className="pe-1">SÖZLEŞME TARİHİ:</b>
              {moment(printData.sozlesmeTarihi).format("DD.MM.YYYY")}
            </div>
            <div className="col text-end me-4">
              <b className="pe-1">SIRA NO:</b>
              500{printData.contractID}
            </div>
          </div>

          <hr className="mx-4 my-1" />

          <div
            className="row mt-3"
            style={{
              padding: "0px 50px",
              fontSize: ".9em",
              lineHeight: "1.45em",
            }}
          >
            <div className="col-12 fw-bold fs-5 mt-1 px-0">
              MÜŞTERİ BİLGİLERİ
            </div>
            <div className="row mt-2">
              <div className="col fw-bold">• DAMAT:</div>
              <div className="row px-4">{printData.damatAdi}</div>
            </div>

            <div className="row mt-1">
              <div className="col fw-bold">• GELİN:</div>
              <div className="row px-4">{printData.gelinAdi}</div>
            </div>
            <div className="row mt-1">
              <div className="col fw-bold">• TELEFON:</div>
              <div className="row px-4">{printData.irtibatTelefonu}</div>
            </div>
            {printData.fotoAlbumDisCekimTarihi && (
              <div className="row mt-1">
                <div className="col fw-bold">• DIŞ ÇEKİM:</div>
                <div className="row px-4">
                  {moment(printData.fotoAlbumDisCekimTarihi).format(
                    "DD.MM.YYYY",
                  )}
                  {" - "}
                  {moment
                    .utc(printData.fotoAlbumDisCekimTarihi)
                    .format("HH:mm")}
                </div>
              </div>
            )}
            {/*MÜŞTERİ BİLGİLERİ BİTİŞ*/}
          </div>

          <hr className="mx-4 mb-1 mt-3" />

          {/*GELİNLİK BİLGİLERİ BAŞLANGIÇ*/}
          <div
            className="row mt-3"
            style={{
              padding: "0px 50px",
              fontSize: ".9em",
              lineHeight: "1.45em",
            }}
          >
            <div className="col-12 fw-bold fs-5 mt-1 px-0">DETAYLAR</div>
            <div className="row my-2">
              <div className="col fw-bold">• PAKET:</div>
              <div className="row px-4">
                {printData.secilenPaket +
                  " " +
                  printData.secilenPaketFiyat +
                  "₺"}
              </div>
            </div>
            {/* TODO:AÇIKLAMA EKLEMEYİ UNUTMA */}
            <div className="row mt-1">
              {printData.fotoAlbumEkstra1 ||
              printData.fotoAlbumEkstra2 ||
              printData.fotoAlbumEkstra3 ? (
                <>
                  <div className="col-auto fw-bold">EKSTRA İSTENENLER:</div>
                  <div className="row px-4">
                    <div className="col-auto p-0">
                      {printData.fotoAlbumEkstra1
                        ? "• " +
                          printData.fotoAlbumEkstra1 +
                          " - " +
                          printData.fotoAlbumEkstra1ucret +
                          "₺"
                        : ""}
                    </div>
                    <div className="col-auto p-0 ms-1">
                      {printData.fotoAlbumEkstra2
                        ? "• " +
                          printData.fotoAlbumEkstra2 +
                          " - " +
                          printData.fotoAlbumEkstra2ucret +
                          "₺"
                        : ""}
                    </div>
                    <div className="col-auto p-0 ms-1">
                      {printData.fotoAlbumEkstra3
                        ? "• " +
                          printData.fotoAlbumEkstra3 +
                          " - " +
                          printData.fotoAlbumEkstra3ucret +
                          "₺"
                        : ""}
                    </div>
                  </div>
                </>
              ) : undefined}
            </div>
          </div>
          {/*GELİNLİK BİLGİLERİ BİTİŞ*/}

          <hr className="mx-4 my-1" />

          {printData.aciklama && (
            <>
              <div
                className="row mt-3"
                style={{
                  padding: "0px 50px",
                  fontSize: ".9em",
                  lineHeight: "1.45em",
                }}
              >
                <div className="col-12 fw-bold fs-5 mt-1 px-0">AÇIKLAMA</div>
                <div className="row mt-2">
                  <div className="row px-4">{printData.aciklama}</div>
                </div>
              </div>
              <hr className="mx-4 mb-1 mt-3" />
            </>
          )}

          <div
            className="row fw-bold my-auto"
            style={{
              fontSize: ".72em",
              padding: "0px 25px 0px 50px",
              lineHeight: "1.5em",
              letterSpacing: ".25px",
            }}
          >
            <div className="row ">
              • DİJİTAL FOTOĞRAFLARINIZ ÜÇ İŞ GÜNÜ İÇİNDE TESLİM EDİLİR.
            </div>
            <div className="row ">
              • ALBÜM TESLİMATI FOTOĞRAF SEÇİMİNDEN BİR AY SONRADIR.
            </div>
            <div className="row ">
              • KLİP TESLİMATI FOTOĞRAF ÇEKİMİNDEN BİR AY SONRADIR.
            </div>
            <div className="row ">
              • ALBÜM İÇİN FOTOĞRAF SEÇİM SÜRESİ İKİ AYDIR. İKİ AYI GEÇTİĞİ
              TAKDİRDE MALİYET ARTIŞLARI MÜŞTERİYE YANSITILIR.
            </div>
            <div className="row ">
              • ANLAŞILMIŞ OLAN ÇEKİM TARİHİ VE SAATİNDE ÇİFTLER VE
              FOTOĞRAFÇIMIZ ÇEKİM YERİNDE OLMAK ZORUNDADIR.
            </div>
            <div className="row ">
              • GELİN VE DAMAT ÇEKİM YAPILACAK YERE GEÇ KALDIĞI TAKDİRDE ÇEKİM
              SÜRESİ UZAYAMAZ. SAAT ARALIĞI DOLDUĞUNDA ÇEKİM SONLANDIRILIR.
            </div>
            <div className="row ">
              • GELİN VEYA DAMAT SÖZLEŞMEYİ İPTAL ETTİĞİ TAKTİRDE KAPOR VE PEŞİN
              ÖDEME İADESİ YAPILMAYACAKTIR.
            </div>
          </div>

          <div className="row mt-auto justify-content-center mb-5 border-top pt-4">
            <div className="col-3 fs-5 fw-bold">SATICI:</div>
            <div className="col-4">
              <div className="row fs-5 fw-bold">ALICI:</div>
              <div className="row">
                <div className="col p-0 pe-1" style={{ height: "45px" }}>
                  {printData.damatAdi}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col p-0 fw-bold">TOPLAM:</div>
                <div className="col p-0 mt-auto">{printData.odemeToplam} ₺</div>
              </div>
              <div className="row">
                <div className="col p-0 fw-bold">KAPORA:</div>
                <div className="col p-0 mt-auto ps-1">
                  {printData.odemeAlinan} ₺
                </div>
              </div>
              {Number(printData.odemeIndirim) > 0 && (
                <div className="row">
                  <div className="col p-0 fw-bold">İSKONTO:</div>
                  <div className="col p-0 mt-auto ps-1">
                    {printData.odemeIndirim} ₺
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col p-0 fw-bold">KALAN:</div>
                <div className="col p-0 mt-auto">{printData.odemeKalan} ₺</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPhotoPrintCanvas;
