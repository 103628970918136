import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import React, { Fragment, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login({ onRedirect, hideAll }) {
  const ValidationSchema = Yup.object().shape({
    username: Yup.string().required("Kullanıcı adı gerekli"),
    password: Yup.string().required("Şifre gerekli"),
  });

  const [initialValues, setInitialValues] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await fetch(process.env.API_URL + "/auth/login", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password,
        }),
      });

      if (result.ok) {
        const currentDate = new Date();
        const nextWeekDate = new Date(
          currentDate.getTime() + 7 * 24 * 60 * 60 * 1000,
        );
        const response = await result.json();
        document.cookie =
          "token=" +
          response.token +
          ";" +
          "expires=" +
          nextWeekDate.toString();
        await new Promise((resolve) => setTimeout(resolve, 100));
        onRedirect();
      } else if (result.status === 401) {
        hideAll();
        toast.error("Kullanıcı adı veya şifre yanlış", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      } else {
        hideAll();
        toast.error("Beklenmedik hata", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      }
    } catch (error) {
      hideAll();
      toast.error("Sunucuya bağlanırken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 50));

    setSubmitting(false);
  };

  return (
    <Fragment>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="loginContainer container bg-light shadow border rounded-3  w-100 p-3 p-md-5"
          style={{ maxWidth: "500px" }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="row mt-2">
                  <div className="col fs-4 fw-bold">Giriş Yap</div>
                </div>
                <div className="row mt-3 pt-4">
                  <div className="form-floating col-md mb-3">
                    <Field
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Kullanıcı Adı"
                    />
                    <label className="form-label ms-3" htmlFor="username">
                      Kullanıcı Adı
                    </label>
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-danger mt-1 ms-1"
                    />
                  </div>
                </div>
                <div className="row mt-md-2">
                  <div className="form-floating col-md mb-3">
                    <Field
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Prenses Gelinlik"
                    />
                    <label className="form-label ms-3" htmlFor="password">
                      Şifre
                    </label>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger mt-1 ms-1"
                    />
                  </div>
                </div>

                <div className="row mt-2 mb-2">
                  <div className="col">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-success shadow fw-bold"
                    >
                      <i className="bi bi-door-open-fill me-1" />
                      Giriş Yap
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition:Flip
      />
    </Fragment>
  );
}

export default Login;
