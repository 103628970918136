export const messages = {
  week: "Hafta",
  work_week: "Çalışma Haftası",
  day: "Gün",
  month: "Ay",
  previous: "Önceki",
  next: "Sonraki",
  today: "Bugün",
  date: "Tarih",
  time: "Zaman",
  event: "Etkinlik",
  noEventsInRange: "Bu aralıkta etkinlik yok.",
  tomorrow: "Yarın",
  yesterday: "Dün",
  showMore: (total) => `+${total} tane daha`,
};
