import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ContractHistoryModal({ toggleModal, type, data }) {
  const [fetchStatus, setFetch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logData, setLogData] = useState();

  const url = `${process.env.API_URL}${
    type === "photo" ? "/photoContracts/log/?" : "/bridalContracts/log/?"
  }${new URLSearchParams({ id: data }).toString()}`;

  useEffect(() => {
    if (fetchStatus) {
      setLoading(true);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setLogData(actualData);
        })
        .catch(() => {
          setError("Sözleşme bilgileri alınırken bir hata meydana geldi");
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setFetch(false);
          }, 150);
        });
    }
  }, [fetchStatus, data]);

  const renderLogs = () => {
    return logData.map((logData, index) => (
      <>
        <div key={index} className="">
          {logData.entry}
        </div>
        <hr className="my-1" />
      </>
    ));
  };

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size="xl"
        fullscreen="lg-down"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Güncelleme Geçmişi</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ? (
            <div className="d-flex justify-content-center align-middle">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : error ? (
            <div className="row ">
              <div className="col-md-4 mx-auto text-center">
                <div className="alert alert-danger mx-0">
                  <i className="bi bi-exclamation-circle-fill" /> {error}
                </div>
                <button
                  className="btn btn-primary fw-bold focus-ring"
                  onClick={() => {
                    setFetch(true);
                  }}
                >
                  Tekrar dene
                </button>
              </div>
            </div>
          ) : logData.length > 0 ? (
            <>{renderLogs()}</>
          ) : (
            <div className="text-center p-5 fs-5 fw-medium">
              Bu sözleşme için güncelleme geçmişi bulunamadı.
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContractHistoryModal;
