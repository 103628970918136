import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { usePrintContext } from "../contextWrapper/PrintContext";
import "./ListPrintCanvas.css";

export default function ListPrintCanvas() {
  const {
    secondPrintData,
    fazlaTutar,
    printContext,
    listCurrentPage,
    switchBackToSecondReality,
    secondShouldPrint,
    setSecondShouldPrint,
  } = usePrintContext();

  const componentRef = React.useRef();

  const handleLandscapePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (secondShouldPrint) {
      handleLandscapePrint();

      switchBackToSecondReality();

      setSecondShouldPrint(false);
    }
  }, [
    secondPrintData,
    secondShouldPrint,
    handleLandscapePrint,
    switchBackToSecondReality,
    setSecondShouldPrint,
  ]);

  const renderTableHeads = () => {
    switch (printContext) {
      case "photo":
        return (
          <tr className="table-success">
            <th className="text-center" style={{ width: "50px" }}>
              NO
            </th>
            <th className="text-center" style={{ width: "125px" }}>
              İSİMLER
            </th>
            <th className="text-center" style={{ width: "90px" }}>
              SEÇİLEN PAKET
            </th>
            <th className="text-center" style={{ width: "70px" }}>
              PAKET ÜCRETİ
            </th>
            <th className="text-center" style={{ width: "150px" }}>
              ALBÜM EKSTRALARI
            </th>
            <th className="text-center" style={{ width: "82px" }}>
              ALBÜM FİYAT
            </th>
            <th className="text-center" style={{ width: "70px" }}>
              TOPLAM
            </th>
            <th className="text-center" style={{ width: "70px" }}>
              KAPORA
            </th>
            <th className="text-center" style={{ width: "70px" }}>
              KALAN
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              DIŞ ÇEKİM
            </th>
            <th className="text-center" style={{ width: "25px" }}>
              DURUM
            </th>
            <th className="text-center" style={{ width: "165px" }}>
              DETAY
            </th>
          </tr>
        );
      case "liste":
        return (
          <tr className="table-success">
            <th className="text-center" style={{ width: "50px" }}>
              NO
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              İSİMLER
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              MODEL
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              TAÇ
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              DUVAK
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              TERZİ
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              KURU T.
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              KINALIK
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              DAMATLIK
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              G TOPLAM
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              A ÜCRET
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              A EXTRALAR
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              TOPLAM
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              KAPORA
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              KALAN
            </th>
            <th className="text-center" style={{ width: "50px" }}>
              GEL. TESLİM
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              DIŞ ÇEKİM
            </th>
            <th className="text-center" style={{ width: "125px" }}>
              DETAY
            </th>
          </tr>
        );
      case "gelinlik":
        return (
          <tr className="table-success">
            <th className="text-center" style={{ width: "50px" }}>
              NO
            </th>
            <th className="text-center" style={{ width: "90px" }}>
              SÖZLEŞME TAR
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              İSİMLER
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              TEL NO
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              DÜĞÜN TAR
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              GELİNLİK MODELİ
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              GELİNLİK EKSTRASI
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              KINALIK SEÇİMİ
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              KAFTAN SEÇİMİ
            </th>
            <th className="text-center" style={{ width: "90px" }}>
              KIN/KAF. KİRALİK BAŞ.
            </th>
            <th className="text-center" style={{ width: "90px" }}>
              KIN/KAF. KİRALİK BİT.
            </th>
          </tr>
        );
      case "teslimat":
        return (
          <tr className="table-success">
            <th className="text-center" style={{ width: "50px" }}>
              NO
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              SÖZLEŞME TAR
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              İSİMLER
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              TEL NO
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              DÜĞÜN TAR
            </th>
            <th className="text-center" style={{ width: "110px" }}>
              GELİNLİK MODELİ
            </th>
            <th className="text-center" style={{ width: "130px" }}>
              GELİNLİK EKSTRASI
            </th>
            <th className="text-center" style={{ width: "130px" }}>
              GELİNLİK TESLİMATI
            </th>
            <th className="text-center" style={{ width: "220px" }}>
              AÇIKLAMA
            </th>
          </tr>
        );
      case "cekim":
        return (
          <tr className="table-success">
            <th className="text-center" style={{ width: "50px" }}>
              NO
            </th>
            <th className="text-center" style={{ width: "100px" }}>
              SÖZLEŞME TAR
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              İSİMLER
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              TEL NO
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              DÜĞÜN TAR
            </th>
            <th className="text-center" style={{ width: "110px" }}>
              GELİNLİK MODELİ
            </th>
            <th className="text-center" style={{ width: "130px" }}>
              GELİNLİK TESLİMATI
            </th>
            <th className="text-center" style={{ width: "130px" }}>
              DIŞ ÇEKİM TARİHİ
            </th>
            <th className="text-center" style={{ width: "220px" }}>
              AÇIKLAMA
            </th>
          </tr>
        );
      default:
        break;
    }
  };

  const renderList = () => {
    if (secondPrintData.length > 0) {
      switch (printContext) {
        case "photo":
          // console.log(secondPrintData);
          return secondPrintData.map((data, index) => (
            <tr
              key={index}
              className={`${data.type === "bridal" ? "table-secondary" : "table-light"}`}
              style={{ lineHeight: "8px", fontSize: "10px", maxHeight: "40px" }}
            >
              {data.type === "photo" ? (
                <td className="text-center border">500{data.contractID}</td>
              ) : (
                <td className="text-center border">100{data.contractID}</td>
              )}
              <td className="text-center border">
                {data.damatAdi.split(" ")[0]} {data.gelinAdi.split(" ")[0]}
              </td>
              {data.type === "photo" ? (
                <td className="text-center border">{data.secilenPaket}</td>
              ) : (
                <td className="text-center border">{data.gelinlikModeli}</td>
              )}
              {data.type === "photo" ? (
                <td className="text-center border">{data.secilenPaketFiyat}</td>
              ) : Number(data.gelinlikOzelDikim) > 0 ? (
                <td className="text-center border">{data.gelinlikOzelDikim}</td>
              ) : (
                <td className="text-center border">{data.gelinlikEkstrasi}</td>
              )}
              <td className="text-center border">
                {(data.fotoAlbumEkstra1 ? data.fotoAlbumEkstra1 : "") +
                  " " +
                  (data.fotoAlbumEkstra2 ? data.fotoAlbumEkstra2 : "") +
                  " " +
                  (data.fotoAlbumEkstra3 ? data.fotoAlbumEkstra3 : "")}
              </td>
              <td className="text-center border">
                {(data.fotoAlbumEkstra1
                  ? Number(data.fotoAlbumEkstra1ucret)
                  : 0) +
                  (data.fotoAlbumEkstra2
                    ? Number(data.fotoAlbumEkstra2ucret)
                    : 0) +
                  (data.fotoAlbumEkstra3
                    ? Number(data.fotoAlbumEkstra3ucret)
                    : 0)}
              </td>
              <td className="text-center border">{data.odemeToplam}</td>
              <td className="text-center border">{data.odemeAlinan}</td>
              <td className="text-center border">{data.odemeKalan}</td>
              <td className="text-center border">
                {data.fotoAlbumDisCekimTarihi
                  ? moment
                      .utc(data.fotoAlbumDisCekimTarihi)
                      .format("DD/MM/YYYY") +
                    " " +
                    moment.utc(data.fotoAlbumDisCekimTarihi).format("HH:mm")
                  : ""}
              </td>
              <td className="text-center border">
                {data.tab === "cancelled"
                  ? "'İptal Edildi'"
                  : data.tab === "archive"
                    ? "'Arşivlendi'"
                    : data.tab === "completed"
                      ? "Tamamlandı"
                      : "Aktif"}
              </td>
              <td className="text-center border">
                {data.aciklama ? data.aciklama : ""}
              </td>
            </tr>
          ));
        case "liste":
          return secondPrintData.map((data, index) => (
            <tr
              key={index}
              className={`${data.tab === "archive" ? "table-primary" : ""} ${data.tab === "cancelled" ? "table-light" : data.tab === "completed" ? "table-info" : ""} overflow-hidden`}
              style={{ lineHeight: "8px", fontSize: "10px", maxHeight: "40px" }}
            >
              <td className="text-center border">100{data.contractID}</td>
              <td className="text-center border">
                {data.damatAdi.split(" ")[0]} {data.gelinAdi.split(" ")[0]}
              </td>
              <td className="text-center border">
                {Number(data.gelinlikOzelDikim) > 0
                  ? data.gelinlikOzelDikim
                  : data.gelinlikEkstrasi}
              </td>
              <td className="text-center border">{data.tacUcreti}</td>
              <td className="text-center border">{data.duvakUcreti}</td>
              <td className="text-center border">{data.terzilikUcreti}</td>
              <td className="text-center border">{data.kuruTemizlemeUcreti}</td>
              <td className="text-center border">{data.kaftanUcreti}</td>
              <td className="text-center border">{data.damatlikUcreti}</td>
              <td className="text-center border">
                {(Number(data.gelinlikOzelDikim) > 0
                  ? Number(data.gelinlikOzelDikim)
                  : Number(data.gelinlikEkstrasi)) +
                  Number(data.tacUcreti) +
                  Number(data.duvakUcreti) +
                  Number(data.terzilikUcreti) +
                  Number(data.kuruTemizlemeUcreti) +
                  Number(data.kaftanUcreti) +
                  Number(data.damatlikUcreti) || ""}
              </td>
              <td className="text-center border">{data.albumUcreti}</td>
              <td className="text-center border">
                {(data.fotoAlbumEkstra1
                  ? Number(data.fotoAlbumEkstra1ucret)
                  : 0) +
                  (data.fotoAlbumEkstra2
                    ? Number(data.fotoAlbumEkstra2ucret)
                    : 0) +
                  (data.fotoAlbumEkstra3
                    ? Number(data.fotoAlbumEkstra3ucret)
                    : 0)}
              </td>
              <td className="text-center border">{data.odemeToplam}</td>
              <td className="text-center border">{data.odemeAlinan}</td>
              <td className="text-center border">{data.odemeKalan}</td>
              <td className="text-center border">
                {data.gelinlikTeslimati
                  ? moment.utc(data.gelinlikTeslimati).format("DD/MM/YYYY")
                  : ""}
              </td>
              <td className="text-center border">
                {data.fotoAlbumDisCekimTarihi
                  ? moment
                      .utc(data.fotoAlbumDisCekimTarihi)
                      .format("DD/MM/YYYY") +
                    " " +
                    moment.utc(data.fotoAlbumDisCekimTarihi).format("HH:mm")
                  : ""}
              </td>
              <td className="text-center border">
                {(data.tab === "cancelled"
                  ? "'İptal Edildi'"
                  : data.tab === "archive"
                    ? "'Arşivlendi'"
                    : data.tab === "completed"
                      ? "Tamamlandı"
                      : " ") +
                  " " +
                  (data.aciklama ? data.aciklama : "")}
              </td>
            </tr>
          ));
        case "gelinlik":
          return secondPrintData.map((data, index) => (
            <tr
              key={index}
              className={`${data.tab === "archive" ? "table-primary" : ""} ${data.tab === "cancelled" ? "table-light" : ""} overflow-hidden`}
              style={{ lineHeight: "8px", fontSize: "10px", maxHeight: "40px" }}
            >
              <td className="text-center border">100{data.contractID}</td>
              <td className="text-center border">
                {moment(data.sozlesmeTarihi).format("DD/MM/YYYY")}
              </td>
              <td className="text-center border">
                {data.damatAdi.split(" ")[0]} {data.gelinAdi.split(" ")[0]}
              </td>
              <td className="text-center border">
                {data.damatTelefonu} {data.gelinTelefonu}
              </td>
              <td className="text-center border">
                {moment(data.dugunTarihi).format("DD/MM/YYYY")}
              </td>
              <td className="text-center border">{data.gelinlikModeli}</td>
              <td className="text-center border">
                {Number(data.gelinlikOzelDikim) > 0
                  ? data.gelinlikOzelDikim
                  : data.gelinlikEkstrasi}
              </td>
              <td className="text-center border">
                {data.kinalikID ? "Var" : "Seçilmedi"}
              </td>
              <td className="text-center border">
                {data.kaftanID ? "Var" : "Seçilmedi"}
              </td>
              <td className="text-center border">
                {data.kinalikKiralikBaslangic
                  ? moment(data.kinalikKiralikBaslangic).format("DD/MM/YYYY")
                  : "Seçilmedi"}
              </td>
              <td className="text-center border">
                {data.kinalikKiralikBitis
                  ? moment(data.kinalikKiralikBitis).format("DD/MM/YYYY")
                  : "Seçilmedi"}
              </td>
            </tr>
          ));
        case "teslimat":
          return secondPrintData.map((data, index) => (
            <tr
              key={index}
              className={`${data.tab === "archive" ? "table-primary" : ""} ${data.tab === "cancelled" ? "table-light" : ""} overflow-hidden`}
              style={{ lineHeight: "8px", fontSize: "10px", maxHeight: "40px" }}
            >
              <td className="text-center border">100{data.contractID}</td>
              <td className="text-center border">
                {moment(data.sozlesmeTarihi).format("DD/MM/YYYY")}
              </td>
              <td className="text-center border">
                {data.damatAdi.split(" ")[0]} {data.gelinAdi.split(" ")[0]}
              </td>
              <td className="text-center border">
                {data.damatTelefonu} {data.gelinTelefonu}
              </td>
              <td className="text-center border">
                {moment(data.dugunTarihi).format("DD/MM/YYYY")}
              </td>
              <td className="text-center border">{data.gelinlikModeli}</td>
              <td className="text-center border">
                {Number(data.gelinlikOzelDikim) > 0
                  ? data.gelinlikOzelDikim
                  : data.gelinlikEkstrasi}
              </td>
              <td className="text-center border">
                {data.gelinlikTeslimati
                  ? moment(data.gelinlikTeslimati).format("DD/MM/YYYY")
                  : "Seçilmedi"}
              </td>
              <td className="text-center border">
                {data.aciklama ? data.aciklama : "Belirtilmedi"}
              </td>
            </tr>
          ));
        case "cekim":
          return secondPrintData.map((data, index) => (
            <tr
              key={index}
              className={`${data.tab === "archive" ? "table-primary" : ""} ${data.tab === "cancelled" ? "table-light" : ""} overflow-hidden`}
              style={{ lineHeight: "8px", fontSize: "10px", maxHeight: "40px" }}
            >
              <td className="text-center border">100{data.contractID}</td>
              <td className="text-center border">
                {moment(data.sozlesmeTarihi).format("DD/MM/YYYY")}
              </td>
              <td className="text-center border">
                {data.damatAdi.split(" ")[0]} {data.gelinAdi.split(" ")[0]}
              </td>
              <td className="text-center border">
                {data.damatTelefonu} {data.gelinTelefonu}
              </td>
              <td className="text-center border">
                {moment(data.dugunTarihi).format("DD/MM/YYYY")}
              </td>
              <td className="text-center border">{data.gelinlikModeli}</td>
              <td className="text-center border">
                {data.gelinlikTeslimati
                  ? moment(data.gelinlikTeslimati).format("DD/MM/YYYY")
                  : "Seçilmedi"}
              </td>
              <td className="text-center border">
                {data.fotoAlbumDisCekimTarihi
                  ? moment
                      .utc(data.fotoAlbumDisCekimTarihi)
                      .format("DD/MM/YYYY HH:mm")
                  : "Belirtilmedi"}
              </td>
              <td className="text-center border">
                {data.aciklama ? data.aciklama : "Belirtilmedi"}
              </td>
            </tr>
          ));
        default:
          break;
      }
    }
  };

  const renderTotal = () => {
    if (secondPrintData.length > 0) {
      switch (printContext) {
        case "photo":
          return (
            <>
              <tr
                className="table-secondary fw-bold"
                style={{ lineHeight: "5px" }}
              >
                <td className="text-center border">{""}</td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.secilenPaketFiyat),
                    0,
                  )}
                </td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) =>
                      a +
                      (b.fotoAlbumEkstra1
                        ? Number(b.fotoAlbumEkstra1ucret)
                        : 0) +
                      (b.fotoAlbumEkstra2
                        ? Number(b.fotoAlbumEkstra2ucret)
                        : 0) +
                      (b.fotoAlbumEkstra3
                        ? Number(b.fotoAlbumEkstra3ucret)
                        : 0),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.odemeToplam),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.odemeAlinan),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.odemeKalan),
                    0,
                  )}
                </td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">{""}</td>
                <td className="text-center border"></td>
              </tr>
            </>
          );
        case "liste":
          return (
            <>
              <tr
                className="table-secondary fw-bold"
                style={{ lineHeight: "5px" }}
              >
                <td className="text-center border">{""}</td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) =>
                      a +
                      (Number(b.gelinlikOzelDikim) > 0
                        ? Number(b.gelinlikOzelDikim)
                        : Number(b.gelinlikEkstrasi)),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce((a, b) => a + Number(b.tacUcreti), 0)}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.duvakUcreti),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.terzilikUcreti),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.kuruTemizlemeUcreti),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.kaftanUcreti),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.damatlikUcreti),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (total, item) =>
                      total +
                      (Number(
                        item.gelinlikOzelDikim > 0
                          ? item.gelinlikOzelDikim
                          : item.gelinlikEkstrasi,
                      ) +
                        Number(item.tacUcreti) +
                        Number(item.duvakUcreti) +
                        Number(item.terzilikUcreti) +
                        Number(item.kuruTemizlemeUcreti) +
                        Number(item.kaftanUcreti) +
                        Number(item.damatlikUcreti)),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.albumUcreti),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) =>
                      a +
                      (b.fotoAlbumEkstra1
                        ? Number(b.fotoAlbumEkstra1ucret)
                        : 0) +
                      (b.fotoAlbumEkstra2
                        ? Number(b.fotoAlbumEkstra2ucret)
                        : 0) +
                      (b.fotoAlbumEkstra3
                        ? Number(b.fotoAlbumEkstra3ucret)
                        : 0),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.odemeToplam),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.odemeAlinan),
                    0,
                  )}
                </td>
                <td className="text-center border">
                  {secondPrintData.reduce(
                    (a, b) => a + Number(b.odemeKalan),
                    0,
                  )}
                </td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">{""}</td>
                <td className="text-center border">SAYFA: {listCurrentPage}</td>
              </tr>
              {fazlaTutar && (
                <tr
                  className="table-light fw-bold"
                  style={{ lineHeight: "5px" }}
                >
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-end border">
                    {secondPrintData.length + " x"}
                  </td>
                  <td className="text-start border">{fazlaTutar + " ="}</td>
                  <td className="text-start border">
                    {" "}
                    {fazlaTutar * secondPrintData.length}
                  </td>
                  <td className="text-end border">{"TOPLAM"}</td>
                  <td className="text-start border">
                    {secondPrintData.reduce(
                      (total, item) =>
                        total +
                        (Number(
                          item.gelinlikOzelDikim > 0
                            ? item.gelinlikOzelDikim
                            : item.gelinlikEkstrasi,
                        ) +
                          Number(item.tacUcreti) +
                          Number(item.duvakUcreti) +
                          Number(item.terzilikUcreti) +
                          Number(item.kuruTemizlemeUcreti) +
                          Number(item.kaftanUcreti)),
                      0,
                    ) +
                      Number(fazlaTutar) * secondPrintData.length}
                  </td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                  <td className="text-center border">{""}</td>
                </tr>
              )}
            </>
          );
        default:
          break;
      }
    }
  };

  return (
    <>
      <div className="container-fluid bg-white border mt-3 d-none">
        <div ref={componentRef} className="" id="secondPrintCanvas">
          <div className="">
            <div
              className="table table-bordered border"
              style={{ fontSize: "9px" }}
            >
              <thead className="">{renderTableHeads()}</thead>
              <tbody>
                {renderList()}
                {renderTotal()}
              </tbody>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
