import React, { Fragment, useMemo, useState, useEffect } from "react";
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import tr from "date-fns/locale/tr";
import moment from "moment";
import { messages } from "./defaults";
import DetailModal from "./DetailModal";
import "./Calendar.css";
import PrintContractsAsAListDaily from "./print/PrintContractsAsAListDaily";

const locales = {
  tr: tr,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function Calendar() {
  const { views, ...otherProps } = useMemo(
    () => ({
      views: {
        week: true,
        month: true,
        day: true,
        agenda: false,
      },
    }),
    [],
  );

  const [data, setData] = useState(false);
  const [colorData, setColorData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [modalData, setModalData] = useState(false);

  const [fetchStatus, setFetch] = useState(true);

  const toggleModal = (data) => {
    setModal(!modal);
    setPrintModal(false);
    setModalData(data);
  };

  const togglePrintModal = () => {
    setPrintModal(!printModal);
    setModal(false);
  };

  const closeModal = () => {
    setModal(false);
    setPrintModal(false);
    setModalData(false);
  };

  useEffect(() => {
    if (fetchStatus) {
      setLoading(true);
      fetch(process.env.API_URL + "/calendar", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            setError(null);
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setData(
            actualData.map((item) => ({
              ...item,
              start: new Date(item.start),
              end: new Date(item.end),
            })),
          );
        })
        .catch((err) => {
          if (err.status === 401) {
            setError(
              "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            );
          } else if (err.message === "Failed to fetch") {
            setError("Veritabanıyla iletişim kurulamadı.");
          } else
            setError(
              "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            );
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setFetch(false);
          }, 100);
        });
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (fetchStatus) {
      setLoading(true);
      fetch(process.env.API_URL + "/calendar/colors", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            setError(null);
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setColorData(actualData);
        })
        .catch((err) => {
          if (err.status === 401) {
            setError(
              "401 - Kullanıcı yetkileri geçersiz, lütfen tekrar giriş yapınız.",
            );
          } else if (err.message === "Failed to fetch") {
            setError("Veritabanıyla iletişim kurulamadı.");
          } else
            setError(
              "Beklenmedik bir durum oluştu, lütfen biraz bekleyip tekrar deneyin.",
            );
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
            setFetch(false);
          }, 100);
        });
    }
  }, [fetchStatus]);

  const CustomEvent = ({ event }) => {
    const hasExtras =
      event.fotoAlbumEkstra1 ||
      event.fotoAlbumEkstra2 ||
      event.fotoAlbumEkstra3;

    return (
      <div className="p-0 d-flex gap-1">
        <div className="mb-0 fw-medium">
          {moment(event.start).format("HH:mm")}
        </div>
        <div className="mb-0">{event.title.split(" ")[0]}</div>
        {hasExtras && (
          <div className="ms-auto me-1">
            <i
              className="bi bi-circle-fill"
              style={{ color: colorData.extraColor }}
            />
          </div>
        )}
        {event.title === "Hikaye Paket" && (
          <div className={`${hasExtras ? "me-1" : "ms-auto me-1"}}`}>
            <i
              className="bi bi-circle-fill"
              style={{ color: colorData.hikayeColor }}
            />
          </div>
        )}
      </div>
    );
  };

  const CustomWeek = ({ event }) => {
    const hasExtras =
      event.fotoAlbumEkstra1 ||
      event.fotoAlbumEkstra2 ||
      event.fotoAlbumEkstra3;

    return (
      <div className="p-0 d-flex gap-1">
        <div className="" style={{ fontSize: "10px" }}>
          {moment(event.start).format("HH:mm")}{" "}
        </div>
        <div className="mb-0" style={{ fontSize: "10px" }}>
          {event.title.split(" ")[0]}
        </div>
        {hasExtras && (
          <div className="ms-auto me-1">
            <i
              className="bi bi-circle-fill"
              style={{ color: colorData.extraColor }}
            />
          </div>
        )}
        {event.title === "Hikaye Paket" && (
          <div className={`${hasExtras ? "me-1" : "ms-auto me-1"}}`}>
            <i
              className="bi bi-circle-fill"
              style={{ color: colorData.hikayeColor }}
            />
          </div>
        )}
      </div>
    );
  };

  const CustomDay = ({ event }) => {
    const hasExtras =
      event.fotoAlbumEkstra1 ||
      event.fotoAlbumEkstra2 ||
      event.fotoAlbumEkstra3;

    return (
      <div className="p-0 d-flex gap-1 flex-column">
        <div className="row">
          <div className="col-auto pe-0">
            {moment(event.start).format("HH:mm")}
          </div>
          {hasExtras && (
            <div className="col-auto ms-auto me-1">
              <i
                className="bi bi-circle-fill"
                style={{ color: colorData.extraColor }}
              />
            </div>
          )}
          {event.title === "Hikaye Paket" && (
            <div className={`col-auto ${hasExtras ? "me-1" : "ms-auto me-1"}}`}>
              <i
                className="bi bi-circle-fill"
                style={{ color: colorData.hikayeColor }}
              />
            </div>
          )}
        </div>
        <div className="mb-0" style={{ fontSize: "14px" }}>
          {event.title.split(" ")[0]} {event.fotoAlbumEkstra1}{" "}
          {event.fotoAlbumEkstra2} {event.fotoAlbumEkstra3}
        </div>
        <div className="mb-0" style={{ fontSize: "14px" }}>
          Alınan: {event.odemeAlinan}₺ Kalan: {event.odemeKalan}₺
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div
        className="container-md px-md-5 px-1 py-md-3 py-1 5rounded-3 shadow-sm border bg-light my-3 d-flex flex-column"
        style={{ height: "95vh" }}
      >
        {error ? (
          <div
            className="row justify-content-center align-items-center my-auto"
            role="status"
          >
            <div
              className="alert alert-danger col-md-6 text-center py-5"
              role="alert"
            >
              Bir hata meydana geldi!
            </div>
            <div className="col-12 text-center">
              <button
                type="button"
                className="btn btn-outline-danger"
                aria-label="Close"
                onClick={() => setFetch(true)}
              >
                Tekrar dene
              </button>
            </div>
          </div>
        ) : loading ? (
          <div className="d-flex justify-content-center align-center my-auto">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="row d-flex flex-row mb-3 mx-md-0 mx-auto">
              <div className="col-md-auto col-12 text-center d-flex align-items-center justify-content-center mt-2 mt-md-0">
                Hikaye Rengi:
                <i
                  className="bi bi-circle-fill ms-1 me-1"
                  style={{ color: colorData.hikayeColor }}
                />
                Albüm Ekstrası Rengi:
                <i
                  className="bi bi-circle-fill ms-1"
                  style={{ color: colorData.extraColor }}
                />
              </div>
              <div className="col-12 col-md-auto text-center ms-auto text-md-end my-auto pe-0 mt-md-0 mt-2">
                <button
                  className="btn btn-primary ms-3 ms-md-2 px-3 p-2 fw-bold"
                  type="button"
                  onClick={() => {
                    togglePrintModal();
                  }}
                >
                  <i className="me-1 bi bi-printer-fill" />
                  Yazdır
                </button>
              </div>
            </div>
            <BigCalendar
              culture="tr"
              views={views}
              events={data}
              timeslots={2}
              localizer={localizer}
              className="pb-4"
              dayLayoutAlgorithm={"no-overlap"}
              onSelectEvent={(event) => {
                toggleModal(true);
                setModalData(event);
              }}
              formats={{ eventTimeRangeFormat: () => null }}
              defaultView="month"
              components={{
                event: CustomEvent,
                week: { event: CustomWeek },
                day: { event: CustomDay },
              }}
              startAccessor="start"
              endAccessor="end"
              messages={messages}
              defaultDate={new Date()}
            />
          </>
        )}
      </div>

      {modal && (
        <DetailModal
          toggleModal={toggleModal}
          closeModal={closeModal}
          modalData={modalData}
        />
      )}

      {printModal && (
        <PrintContractsAsAListDaily
          toggleModal={togglePrintModal}
          closeModal={closeModal}
        />
      )}
    </Fragment>
  );
}

export default Calendar;
