import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function DetailModal({ toggleModal, closeModal, modalData }) {
  const [fetchStatus, setFetch] = useState(true);
  const [hasAuth, setHasAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(false);
  const pinInputRef = useRef(null);

  const formikRef = useRef();

  const [statuses, setStatuses] = useState([
    {
      id: "cekimStatus",
      status: false,
    },
    {
      id: "fotografStatus",
      status: false,
    },
    {
      id: "baskiStatus",
      status: false,
    },
    {
      id: "teslimatStatus",
      status: false,
    },
  ]);

  const handleSubmitOutsideForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const url = `${process.env.API_URL}${
    modalData.type === "photo"
      ? "/photoContracts/id/?"
      : "/bridalContracts/id/?"
  }${new URLSearchParams({ id: modalData.id }).toString()}`;

  useEffect(() => {
    if (fetchStatus) {
      setLoading(true);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: document.cookie.slice(6),
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        })
        .then((actualData) => {
          setData(actualData);
          setStatuses([
            {
              id: "cekimStatus",
              status: actualData.cekimStatus,
            },
            {
              id: "fotografStatus",
              status: actualData.fotografStatus,
            },
            {
              id: "baskiStatus",
              status: actualData.baskiStatus,
            },
            {
              id: "teslimatStatus",
              status: actualData.teslimatStatus,
            },
          ]);
        })
        .catch(() => {
          setError("Sözleşme bilgileri alınırken bir hata meydana geldi");
        })
        .finally(() => {
          setLoading(false);
          setFetch(false);
        });
    }
  }, [fetchStatus, data]);

  const adminAuth = async (e) => {
    e.preventDefault();
    const result = await fetch(process.env.API_URL + "/auth/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: "duzenleme",
        password: document.getElementById("pinCode").value,
      }),
    });

    if (result.ok) {
      setFetch(true);
      setHasAuth(true);
    } else if (result.status === 401) {
      toast.error("PIN kodu yanlış!", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sunucuya bağlanırken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 50));
  };

  const initialValues = {
    damatAdi: data.damatAdi,
    gelinAdi: data.gelinAdi,
    damatTelefonu: data.damatTelefonu,
    irtibatTelefonu: data.irtibatTelefonu,
    fotoAlbumEkstra1: data.fotoAlbumEkstra1 ? true : false,
    fotoAlbumEkstra2: data.fotoAlbumEkstra2 ? true : false,
    fotoAlbumEkstra3: data.fotoAlbumEkstra3 ? true : false,
    fotoAlbumDisCekimTarihi:
      moment.utc(data.fotoAlbumDisCekimTarihi).format("YYYY-MM-DD HH:mm") ||
      null,
    odemeAlinan: data.odemeAlinan,
  };

  const ValidationSchema = Yup.object().shape({
    odemeAlinan: Yup.number()
      .test("max", "Ön ödeme toplam tutardan fazla olamaz", function (value) {
        return value <= data.odemeToplam;
      })
      .min(1, "Alınan ödeme tutarı sıfırdan yüksek olmalıdır")
      .required("Ödeme tutarı gerekli"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const url = `${process.env.API_URL}${
      modalData.type === "photo"
        ? "/photoContracts/price/?"
        : "/bridalContracts/price/?"
    }`;

    const result = fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        token: document.cookie.slice(6),
      },
      body: JSON.stringify({
        id: data.id,
        data: {
          fotoAlbumDisCekimTarihi:
            moment.utc(values.fotoAlbumDisCekimTarihi) || null,
          odemeTuru: values.odemeTuru,
          odemeAlinan: Number(values.odemeAlinan),
          cekimStatus: statuses[0].status,
          fotografStatus: statuses[1].status,
          baskiStatus: statuses[2].status,
          teslimatStatus: statuses[3].status,
        },
      }),
    });

    const response = await result;

    if (response.ok) {
      toast.success("Sözleşme başarıyla güncellendi!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    } else {
      toast.error("Sözleşme güncellenirken bir hata meydana geldi", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 1200));

    setSubmitting(false);
    setHasAuth(false);
    closeModal();
  };

  return (
    <>
      <Modal
        show={true}
        onHide={toggleModal}
        size={hasAuth ? "xl" : "md"}
        fullscreen="lg-down"
        scrollable
        centered={!hasAuth}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!hasAuth
              ? "Giriş Yap"
              : modalData.type === "photo"
                ? "Fotoğraf Sözleşmesi Güncelle"
                : "Gelinlik Sözleşmesi Güncelle"}
            {hasAuth && (
              <div className="text-muted fs-6">
                Sözleşme Kodu: {modalData.type === "photo" ? "500" : "000"}
                {data.contractID} - Toplam Fiyat: {data.odemeToplam}₺
              </div>
            )}
          </Modal.Title>
        </Modal.Header>

        {error ? (
          <Modal.Body className="d-flex justify-content-center py-5">
            <div className="row" role="status">
              <div
                className="alert alert-danger col-12 text-center"
                role="alert"
              >
                Bir hata meydana geldi!
              </div>
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  aria-label="Close"
                  onClick={() => setFetch(true)}
                >
                  Tekrar dene
                </button>
              </div>
            </div>
          </Modal.Body>
        ) : loading ? (
          <Modal.Body className="d-flex justify-content-center py-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Yükleniyor...</span>
            </div>
          </Modal.Body>
        ) : hasAuth ? (
          <>
            <Modal.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                innerRef={formikRef}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">
                        Müşteri Bilgileri
                      </div>
                    </div>
                    <div className="row px-5 px-md-0 mb-1 justify-content-center">
                      <div className="form-floating col-lg-3 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="damatAdi"
                          name="damatAdi"
                          placeholder="Damat Adı"
                          disabled
                        />
                        <label className="form-label ms-3" htmlFor="damatAdi">
                          Damat Adı
                        </label>
                        <ErrorMessage
                          name="damatAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-lg-3 mb-3">
                        <Field
                          type="text"
                          className="form-control"
                          id="gelinAdi"
                          name="gelinAdi"
                          placeholder="Gelin adı"
                          disabled
                        />
                        <label className="form-label ms-3" htmlFor="gelinAdi">
                          Gelin Adı
                        </label>
                        <ErrorMessage
                          name="gelinAdi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      {modalData.type === "photo" ? (
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="irtibatTelefonu"
                            name="irtibatTelefonu"
                            placeholder="İrtibat Telefonu"
                            disabled
                          />
                          <label className="form-label ms-3" htmlFor="gelinAdi">
                            İrtibat Telefonu
                          </label>
                          <ErrorMessage
                            name="irtibatTelefonu"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      ) : (
                        <div className="form-floating col-lg-3 mb-3">
                          <Field
                            type="text"
                            className="form-control"
                            id="damatTelefonu"
                            name="damatTelefonu"
                            placeholder="Damat Telefonu"
                            disabled
                          />
                          <label className="form-label ms-3" htmlFor="gelinAdi">
                            Damat Telefonu
                          </label>
                          <ErrorMessage
                            name="damatTelefonu"
                            component="div"
                            className="text-danger mt-1 ms-1"
                          />
                        </div>
                      )}
                    </div>

                    <hr className="w-75 mx-auto" />

                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">
                        Çekim Durumu
                      </div>
                    </div>
                    <div className="row px-5 px-md-0 mb-1 justify-content-center">
                      <div className="col-lg-2 mb-3">
                        <div
                          className={`btn w-100 ${statuses[0].status ? "btn-outline-success" : "btn-outline-danger"}`}
                          onClick={() => {
                            setStatuses([
                              {
                                id: "cekimStatus",
                                status: !statuses[0].status,
                              },
                              statuses[1],
                              statuses[2],
                              statuses[3],
                            ]);
                          }}
                        >
                          Çekim
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3">
                        <div
                          className={`btn w-100 ${statuses[1].status ? "btn-outline-success" : "btn-outline-danger"}`}
                          onClick={() => {
                            setStatuses([
                              statuses[0],
                              {
                                id: "fotografStatus",
                                status: !statuses[1].status,
                              },
                              statuses[2],
                              statuses[3],
                            ]);
                          }}
                        >
                          Fotoğraf
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3">
                        <div
                          className={`btn w-100 ${statuses[2].status ? "btn-outline-success" : "btn-outline-danger"}`}
                          onClick={() => {
                            setStatuses([
                              statuses[0],
                              statuses[1],
                              {
                                id: "baskiStatus",
                                status: !statuses[2].status,
                              },
                              statuses[3],
                            ]);
                          }}
                        >
                          Baskı
                        </div>
                      </div>
                      <div className="col-lg-2 mb-3">
                        <div
                          className={`btn w-100 ${statuses[3].status ? "btn-outline-success" : "btn-outline-danger"}`}
                          onClick={() => {
                            setStatuses([
                              statuses[0],
                              statuses[1],
                              statuses[2],
                              {
                                id: "teslimatStatus",
                                status: !statuses[3].status,
                              },
                            ]);
                          }}
                        >
                          Teslimat
                        </div>
                      </div>
                    </div>

                    <hr className="w-75 mx-auto" />

                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">
                        Albüm Bilgileri
                      </div>
                    </div>

                    <div className="row px-5 justify-content-center mt-lg-2 mb-3 fw-medium">
                      <div className="col-lg-auto me-3 my-auto pt-2 pe-lg-0">
                        Seçilen Paket:{" "}
                        {modalData.type === "photo"
                          ? data.secilenPaket
                          : data.gelinlikModeli}
                      </div>
                    </div>

                    {data.fotoAlbumEkstra1 ||
                    data.fotoAlbumEkstra2 ||
                    data.fotoAlbumEkstra3 ? (
                      <div className="row px-5 justify-content-center mt-lg-1 mb-4 fw-medium">
                        <div className="col-lg-auto me-3 my-auto pe-lg-0">
                          Ekstralar:
                          {data.fotoAlbumEkstra1 ? " Klip" : ""}
                          {data.fotoAlbumEkstra2 ? " Aile Albümü" : ""}
                          {data.fotoAlbumEkstra3 ? " Kanvas" : ""}
                        </div>
                      </div>
                    ) : null}

                    <hr className="w-75 mx-auto mt-2" />

                    <div className="row mb-3">
                      <div className="fs-5 fw-bold text-center">
                        Dış Çekim ve Ön Ödeme
                      </div>
                    </div>

                    <div className="row mb-1 px-5 px-md-0 justify-content-center">
                      <div className="form-floating col-md-3 mb-3">
                        <Field
                          type="datetime-local"
                          className="form-control"
                          id="fotoAlbumDisCekimTarihi"
                          name="fotoAlbumDisCekimTarihi"
                        />
                        <label
                          className="form-check-label ms-2 fw-bold"
                          style={{ fontSize: ".8em" }}
                          htmlFor="fotoAlbumDisCekimTarihi"
                        >
                          Dış Çekim Tarihi
                        </label>
                        <ErrorMessage
                          name="fotoAlbumDisCekimTarihi"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                      <div className="form-floating col-md-2 mb-3">
                        <select
                          className="form-select"
                          id="odemeTuru"
                          name="odemeTuru"
                          defaultValue="Nakit"
                          onChange={handleChange}
                        >
                          <option value="Nakit">Nakit</option>
                          <option value="Havale">Havale</option>
                        </select>
                        <label
                          className="form-label ms-2 fw-bold"
                          htmlFor="gelinlikFiltresi"
                          style={{ fontSize: ".95em" }}
                        >
                          Ödeme Türü
                        </label>
                      </div>
                      <div className="form-floating col-lg-2 mb-3">
                        <Field
                          type="text"
                          name="odemeAlinan"
                          placeholder="İskonto"
                        >
                          {({ field }) => (
                            <InputMask
                              type="text"
                              className="form-control"
                              id="odemeAlinan"
                              mask="9999999999"
                              maskChar={""}
                              placeholder=""
                              onChange={handleChange}
                              {...field}
                            />
                          )}
                        </Field>
                        <label
                          className="form-check-label ms-2 "
                          htmlFor="odemeAlinan"
                        >
                          Ön Ödeme
                        </label>

                        <ErrorMessage
                          name="odemeAlinan"
                          component="div"
                          className="text-danger mt-1 ms-1"
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={toggleModal}>
                İptal Et
              </Button>
              <Button variant="primary" onClick={handleSubmitOutsideForm}>
                Güncelle
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body className="py-5 d-flex justify-content-center flex-column">
              <form onSubmit={adminAuth}>
                <div className="row justify-content-center text-md-start text-center mb-3 text-muted">
                  Devam edebilmek için 6 haneli PIN kodunuzu giriniz.
                </div>
                <div className="row justify-content-center mb-4">
                  <div className="col-md-auto">
                    <InputMask
                      mask="999999"
                      maskChar=""
                      type="password"
                      className="form-control"
                      id="pinCode"
                      name="pinCode"
                      placeholder="PIN Kodu"
                      ref={pinInputRef}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 text-muted gap-4">
                  <div
                    className="col-md-4 btn btn-secondary"
                    onClick={toggleModal}
                  >
                    İptal
                  </div>
                  <button type="submit" className="col-md-4 btn btn-primary">
                    Giriş Yap
                  </button>
                </div>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={450}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
        transition:Flip
      />
    </>
  );
}

export default DetailModal;
